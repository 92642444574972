import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetConfig } from 'src/api/config.api'
import { getChainName, getGasToken } from 'src/configs/chainInfo'
import { ConfigLoadingStatus } from 'src/enums'

type InitialStateProps = {
	resolverPaused: boolean
	loading: ConfigLoadingStatus
	availableTokens: IToken[]
	availableNetworks: Record<string, INetwork>
	swapContracts: Record<string, any>
}

const initialState: InitialStateProps = {
	resolverPaused: false,
	loading: ConfigLoadingStatus.loading,
	availableTokens: [],
	availableNetworks: {},
	swapContracts: {},
}

export const getConfig = createAsyncThunk('config/getConfig', async () => {
	try {
		const { deployments }: { deployments: Record<string, any> } = await apiGetConfig()

		const availableTokens: IToken[] = []
		const availableNetworks: Record<string, INetwork> = {}
		const swapContracts: Record<string, any> = { cctp: {}, optimistic: {} }

		Object.keys(deployments).map((networkKey) => {
			const {
				abbreviation,
				assets = {},
				chain_id,
				cctp_id,
				lz_cid,
				contracts = {},
			}: {
				abbreviation: string
				assets: Record<string, any>
				chain_id: number
				cctp_id: number
				lz_cid: number
				contracts: Record<string, string>
			} = deployments[networkKey]

			for (const [key, value] of Object.entries(assets)) {
				availableTokens.push({
					symbol: key,
					logoURI: `/assets/token/${key?.toLowerCase()}.png`,
					name: key,
					unit: key?.toLowerCase(),
					chainId: chain_id,
					zeroChainID: lz_cid,
					address: value.address,
					decimals: value.decimals,
					native: false,
					wrapped: value.wrapped,
				})
			}

			availableNetworks[chain_id] = {
				name: getChainName(networkKey),
				gasToken: getGasToken(networkKey),
				abbreviation,
				icon: `/assets/network/${networkKey}.png`,
				cctp_id,
				lz_id: lz_cid,
			}

			for (const swapContract of Object.entries(contracts)) {
				const [type, address] = swapContract
				if (!swapContracts[type]) {
					swapContracts[type] = {}
				}
				swapContracts[type][chain_id] = address
			}
		})

		return {
			availableTokens,
			availableNetworks,
			swapContracts,
			resolverPaused: false,
		}
	} catch (err) {
		throw new Error((err as Error).message)
	}
})

export const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getConfig.fulfilled, (state, action) => {
			state = { ...state, ...action.payload, loading: ConfigLoadingStatus.loaded }
			return state
		})
		builder.addCase(getConfig.rejected, (state) => {
			state = { ...state, loading: ConfigLoadingStatus.error }
			return state
		})
	},
})

export default configSlice.reducer
