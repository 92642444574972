import React from 'react'
import CountUp from 'react-countup'
import { useNavigate } from 'react-router-dom'
import hourglassLogo from 'src/assets/images/HG-Logo-White-Font-p-500.png'
import machLogo from 'src/assets/images/machLogo.svg'
import Card from 'src/components/Card'
import * as PATH from 'src/configs/paths'
import { truncateAddress } from 'src/utils/truncateAddress'
import { ConnectWallet } from './ConnectWallet'

type Props = {
	account?: string | null
	points: number
}

export const CheckPoints: React.FC<Props> = (props) => {
	const navigate = useNavigate()
	const { account, points } = props

	if (!account) return <ConnectWallet />
	const logo = window.location.href.includes('hourglass') ? hourglassLogo : machLogo

	return (
		<Card>
			<>
				<div>
					<img src={logo} className='h-8' style={{ float: 'left', position: 'absolute' }} />
					<h2 className='pb-4 text-center text-lg font-bold'>Check Miles</h2>
				</div>
				<div className='flex flex-col items-center justify-center rounded-lg bg-black/25 p-6'>
					<h3 className='text-lg italic'>You&#39;ve earned</h3>
					<div className='py-4 text-center text-4xl font-medium'>
						<CountUp end={points} duration={1.75} /> {/* Animated CountUp */}
						<br /> Miles
					</div>
					<div className='text-xs italic'>Connected Wallet: {truncateAddress(account || '')}</div>
				</div>
				<div className='flex flex-col items-center justify-center rounded-lg bg-black/25 p-4'>
					<h4 className='text-sm font-bold'>Rules</h4>
					<p className='pt-2 text-[12px]'>
						We’re keeping it simple: traders earn 1 point for every dollar traded, up to 10,000 points per week. We are
						additionally awarding 1,000 points to the highest volume trader over each 3 hour period.
						<br />
						<br />
						We also want to reward our past users, so we’re awarding 10,000 points to every active wallet prior to our
						points launch.
						<br />
						<br />
						For more details, please click{' '}
						<a href='https://hourglass.ghost.io/points/' target='_blank' rel='noreferrer' className='underline'>
							here
						</a>
						.
					</p>
				</div>
				<button
					className='gradient-button bg-swap-new w-full cursor-pointer rounded-lg border-0 py-3 text-base font-semibold text-white'
					onClick={() => navigate(PATH.LANDING)}
				>
					Launch App
				</button>
			</>
		</Card>
	)
}
