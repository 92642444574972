import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux'
import 'react-tooltip/dist/react-tooltip.css'
//routes
import AppRoutes from './AppRoutes'
import { AuthProvider } from './providers/AuthProvider'
import { Web3ModalProvider } from './providers/Web3ModalProvider'
//stores
import { store } from './redux/store'

// import styles

const App: React.FC = () => {
	return (
		<Provider store={store}>
			<Web3ModalProvider>
				<AuthProvider>
					<AppRoutes />
				</AuthProvider>
			</Web3ModalProvider>
		</Provider>
	)
}

export default App
