/* eslint-disable @typescript-eslint/no-unsafe-return */
import { cacheHalfFullApi } from 'src/libs/axios'

export const apiGetNativeTokenPrices = async () => {
	try {
		const { data } = await cacheHalfFullApi.get('/gas_token_prices')

		return data
	} catch (err) {
		throw new Error((err as Error).message)
	}
}

export const apiGetAssets = async (chainName: string) => {
	try {
		const { data } = await cacheHalfFullApi.get('/get_assets', {
			params: { chain_name: chainName.toLowerCase() },
		});
		
		return data;
	} catch (err) {
		throw new Error((err as Error).message);
	}
};