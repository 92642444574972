import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { useStopwatch } from 'react-timer-hook'
import { Tooltip } from 'react-tooltip'
import cn from 'classnames'
import { IStep } from 'src/@types/transactionStatus'
import BottomArrow from 'src/assets/arrows/bottom-arrow.svg'
import ErrorIcon from 'src/assets/status/error.svg'
import SuccessIcon from 'src/assets/status/success.svg'
import { ResultCode, StepNames } from 'src/enums'
import { useAppSelector } from 'src/hooks/redux'
import { formatNumber } from 'src/utils/formatNumber'
import Button from '../buttons/Button'
import SwapButton from '../buttons/SwapButton'
import ArrowTopRightIcon from '../icons/ArrowTopRight'
import ChevronDownIcon from '../icons/ChevronDown'
import CloseIcon from '../icons/Close'

interface Props {
	isOpen: boolean
	onClose: () => void
	onConfirm: () => void
	sellData: IExchangeData
	buyData: IExchangeData
	currentStep: StepNames
	steps: Partial<Record<StepNames, IStep>>;
	result?: {
		status: ResultCode
		sourceTxHash: string
        destinationTxHash?: string
		message: string
		transactionETA: number
	}
	fees: IFee[]
}

const ReviewSwapModal: React.FC<Props> = ({
	isOpen,
	onClose,
	onConfirm,
	sellData,
	buyData,
	steps = {},
	currentStep = StepNames.UNSTARTED,
	result = undefined,
	fees,
}: Props) => {
	const { availableNetworks } = useAppSelector((state) => state.configSlice)
	const [isGasFeeExpanded, setIsGasFeeExpanded] = useState<boolean>(false)
	const fullTradeTimeTimer = useStopwatch({ autoStart: false })

	useEffect(() => {
		if (result && result.transactionETA && result.status === ResultCode.pending) {
			fullTradeTimeTimer.reset()
		} else {
			fullTradeTimeTimer.reset()
			fullTradeTimeTimer.pause()
		}
	}, [result?.status])

	useEffect(() => {
		if (result && result.transactionETA && result.status === ResultCode.success) {
			fullTradeTimeTimer.pause()
		}
	}, [fullTradeTimeTimer.totalSeconds, result])

	const modalheightForEachStep = useMemo(() => {
		if (!result) {
			return `${350 + (isGasFeeExpanded ? 75 : 0)}px` // Reviewing swap modal step
		} else {
			switch (result.status) {
                case ResultCode.pending:
                    return '425px'
                case ResultCode.success:
                    return '450px'
                default:
                    return '400px'
              }
		}
	}, [steps, result, currentStep, isGasFeeExpanded])

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				ariaHideApp={false}
				className={`fixed left-1/2 top-1/2 flex w-[500px] max-w-[calc(100%-24px)] -translate-x-1/2 -translate-y-1/2 flex-col overflow-hidden rounded-2xl border border-modal-border/50 bg-[#141414] p-2 outline-none transition-all duration-500 sm:p-2`}
				style={{
					content: {
						height: `${modalheightForEachStep}`,
					},
					overlay: {
						backgroundColor: 'rgba(0,0,0,.6)',
					},
				}}
			>
				<div className='mt-2 flex items-center justify-between px-2'>
					<span className='text-lg font-semibold'>Review Order</span>
					<CloseIcon className='cursor-pointer hover:opacity-80' onClick={onClose} />
				</div>

				<div className='mt-2 flex flex-grow flex-col space-y-2'>
					<div className='relative min-h-[110px] overflow-hidden rounded-xl md:min-h-[140px]'>
						<div className='z-[1] mx-auto mt-6 flex w-full max-w-[430px] items-center justify-between'>
							<div className='flex w-20 flex-col items-center justify-center gap-4 sm:w-[100px]'>
								<div className='relative mr-2 sm:mr-[12px]'>
									<img
										src={sellData.token?.logoURI}
										className='relative w-10 rounded-full shadow-[3px_3px_5px_0px_rgba(0,0,0,.55)] sm:w-12 md:w-16'
									/>
									<img
										src={availableNetworks[sellData.networkId]?.icon}
										className='absolute -right-[12px] bottom-0 w-6 rounded-full shadow-[4px_2px_2px_0_rgba(0,0,0,.2)] md:w-8'
									/>
								</div>
								<span className='text-base font-bold sm:text-xl md:text-2xl'>{formatNumber(sellData.value)}</span>
							</div>

							{(!result || (result && fullTradeTimeTimer.isRunning) || (result && result.status === ResultCode.pending)) && (
								<div className='text-medium'>
									<div className='flex flex-col items-center justify-center gap-3'>
										{currentStep !== StepNames.UNSTARTED && <p className='text-[12px] font-semibold'>{steps[currentStep]?.label}</p>}
									</div>

									{(currentStep !== StepNames.UNSTARTED || (result && result.status === ResultCode.pending)) && (
										<div className='flex h-12 items-center justify-center'>
											<div className='ring-loader relative '></div>
										</div>
									)}
								</div>
							)}

							{/* center success or error */}
							<div
								className={cn(
									'flex flex-col items-center justify-center gap-2',
									result && !fullTradeTimeTimer.isRunning && result.status === ResultCode.success ? 'block' : 'hidden'
								)}
							>
								<img src={SuccessIcon} alt='' />
								<p>Success</p>
							</div>
							<div
								className={cn(
									'flex flex-col items-center justify-center gap-2',
									result && !fullTradeTimeTimer.isRunning && result.status === ResultCode.failure ? 'block' : 'hidden'
								)}
							>
								<img src={ErrorIcon} alt='' />
								<p>Failure</p>
							</div>
							<div className='flex w-20 flex-col items-center justify-center gap-4 sm:w-[100px]'>
								<div className='relative mr-[8px] sm:mr-[12px]'>
									<img
										src={buyData.token?.logoURI}
										className='relative w-10 rounded-full shadow-[3px_3px_5px_0px_rgba(0,0,0,.55)] sm:w-12 md:w-16'
									/>
									<img
										src={availableNetworks[buyData.networkId]?.icon}
										className='absolute -right-[12px] bottom-0 w-6 rounded-full shadow-[4px_2px_2px_0_rgba(0,0,0,.2)] md:w-8'
									/>
								</div>
								<span className='text-base font-bold sm:text-xl md:text-2xl'>{formatNumber(buyData.value)}</span>
							</div>
						</div>
					</div>
					<div className='flex flex-grow flex-col space-y-2 text-sm font-normal'>
						{result && (
							<div className='flex flex-grow flex-col items-center space-y-2'>
								{result.message && (result.status !== ResultCode.success || fullTradeTimeTimer.isRunning) && result.message != 'Error will not be filled by the market maker at this time.' && (
									<p className='flex items-center gap-2 text-xs font-[500] text-white'>
										{result.message}
										{/* Show tool tip if order ETA more than 20 minutes */}
										{result && result?.transactionETA >= 60 * 20 && (
											<span
												className='flex h-5 w-5 items-center justify-center rounded-full bg-primary/50 p-2 text-xs text-white'
												data-tooltip-id='long-exchange-notification'
											>
												!
											</span>
										)}
									</p>
								)}

								{/*		New Times   */}
								{result.status === ResultCode.pending && fullTradeTimeTimer.isRunning && (
									<div className='flex flex-col items-center justify-center gap-2 rounded-md p-2'>
										<p className='font-xs text-white/60'>Completing Trade</p>
										{(fullTradeTimeTimer.minutes > 0 || fullTradeTimeTimer.seconds > 0) && (
											<>
												<img src={BottomArrow} alt='' />
												<p className='text-xl font-bold leading-[1]'>
													{fullTradeTimeTimer.minutes}m {fullTradeTimeTimer.seconds}s
												</p>
											</>
										)}
									</div>
								)}

								{!fullTradeTimeTimer.isRunning && (
									<>
										{result.status === ResultCode.success && (
											<p className='!mt-5 text-center text-xs'>
												You can track your order on the{' '}
												<a className='text-highlight-text' href='/orders'>
													Orders
												</a>{' '}
												tab. Please join our
												<a
													className='text-highlight-text'
													href='https://t.me/+7YaDMvg4-sM1YmQx'
													target='_blank'
													rel='noreferrer'
												>
													{' '}
													Telegram
												</a>{' '}
												for support.
											</p>
										)}
									</>
								)}
								{result.sourceTxHash && (
									<>
										<a
                                            className='!mt-auto flex w-full cursor-pointer justify-between rounded-md border border-white/10 px-6 py-4 text-center text-xs font-[500] hover:opacity-80'
                                            href={result.sourceTxHash}
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            View source transaction on explorer
                                            <ArrowTopRightIcon className='h-4 w-4' />
                                        </a>
									</>
								)}
                                {result.destinationTxHash && (
									<>
										<a
											className='flex w-full cursor-pointer justify-between rounded-md border border-white/10 px-6 py-4 text-center text-xs font-[500] hover:opacity-80'
											href={result.destinationTxHash}
											target='_blank'
											rel='noreferrer'
										>
											View destination transaction on explorer
											<ArrowTopRightIcon className='h-4 w-4' />
										</a>
										{/* <div className='flex justify-center'>
											<RetweetButton />
										</div> */}
									</>
								)}
							</div>
						)}
						{!result && (
							<>
								{currentStep === StepNames.UNSTARTED ? (
									<>
										<div className='flex items-center justify-between'>
											<span className='font-light text-sub-text'>Sending:</span>
											<span>
												{sellData.value} {sellData.token?.symbol} on{' '}
												{availableNetworks[sellData.networkId]?.abbreviation}
											</span>
										</div>

										<div className='flex items-center justify-between'>
											<span className='font-light text-sub-text'>Receiving:</span>
											<span>
												{formatNumber(buyData.value)} {buyData.token?.symbol} on{' '}
												{availableNetworks[buyData.networkId]?.abbreviation}
											</span>
										</div>

										<div className='flex items-center justify-between'>
											<span className='font-light text-sub-text'>{!isGasFeeExpanded ? 'Total Fees:' : 'Fees:'}</span>
											<div
												className='flex cursor-pointer items-center'
												onClick={() => setIsGasFeeExpanded((prev) => !prev)}
											>
												{!isGasFeeExpanded && (
													<span>${formatNumber(fees.reduce((res, cur) => (res += cur.value), 0))}</span>
												)}
												<ChevronDownIcon
													className={cn('h-5 w-5 transition-all', isGasFeeExpanded ? 'rotate-180' : '')}
												/>
											</div>
										</div>

										<div
											className={cn(
												isGasFeeExpanded ? 'h-[72px]' : 'h-0',
												'space-y-1 overflow-hidden transition-all duration-500'
											)}
										>
											{fees.map((fee) => (
												<div className='flex items-center justify-between' key={`fee-${fee.label}`}>
													<span className={cn('font-light text-sub-text')}>{fee.label}:</span>
													<span className='text-sub-text'>
														{+formatNumber(fee.value) < 0.01 ? '<$0.01' : `$${formatNumber(fee.value)}`}
													</span>
												</div>
											))}

											<div className='flex items-center justify-between'>
												<span className='font-light text-sub-text'>Total Fees:</span>
												<span className='text-sub-text'>
													${formatNumber(fees.reduce((res, cur) => (res += cur.value), 0))}
												</span>
											</div>
										</div>
									</>
								) : (
									<>{/* Approve in wallet and confirm swap part */}</>
								)}
							</>
						)}
					</div>

					{!result && (
						<>
							{currentStep === StepNames.UNSTARTED ? (
								<SwapButton
									sellData={{ value: 1, networkId: 1 }}
									className='mt-auto w-full'
									onClick={() => onConfirm()}
								/>
							) : (
								<Button disabled className='w-full'>
									{steps[currentStep]?.label || ''}
								</Button>
							)}
						</>
					)}
				</div>
			</Modal>
			{result && result?.transactionETA >= 60 * 20 && (
				<Tooltip
					id='long-exchange-notification'
					style={{ zIndex: 1000, maxWidth: '300px' }}
					render={() => (
						<p className='text-xs'>
							The market maker doesn’t have enough liquidity to fill your swap immediately, so it will be filled using
							CCTP (directly in the case of USDC → USDC swaps, or just to retrieve liquidity on your destination chain
							if you’re swapping to a different coin).
						</p>
					)}
				/>
			)}
		</>
	)
}

export default ReviewSwapModal