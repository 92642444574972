import { SVGProps } from 'react'

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='20'
		height='16'
		viewBox='0 0 20 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M19.7029 2.11415C19.0071 2.42248 18.2596 2.63082 17.4738 2.72498C18.2846 2.23981 18.8912 1.4762 19.1804 0.576649C18.4186 1.02914 17.5849 1.34765 16.7154 1.51832C16.1307 0.894037 15.3563 0.480255 14.5124 0.341211C13.6684 0.202168 12.8022 0.345642 12.0481 0.74936C11.2941 1.15308 10.6944 1.79445 10.3422 2.5739C9.99003 3.35335 9.90503 4.22727 10.1004 5.05998C8.55682 4.98248 7.04677 4.58127 5.66827 3.8824C4.28977 3.18353 3.07362 2.20262 2.09875 1.00332C1.76542 1.57832 1.57375 2.24498 1.57375 2.95498C1.57338 3.59415 1.73078 4.22352 2.03198 4.78726C2.33319 5.351 2.76888 5.83168 3.30042 6.18665C2.68398 6.16703 2.08114 6.00047 1.54208 5.70082V5.75082C1.54202 6.64727 1.85211 7.51613 2.41974 8.20998C2.98736 8.90383 3.77756 9.37992 4.65625 9.55748C4.0844 9.71225 3.48486 9.73504 2.90292 9.62415C3.15083 10.3955 3.63375 11.07 4.28406 11.5533C4.93437 12.0365 5.71951 12.3043 6.52958 12.3191C5.15444 13.3987 3.45616 13.9842 1.70792 13.9816C1.39823 13.9817 1.08881 13.9636 0.78125 13.9275C2.55581 15.0685 4.62153 15.674 6.73125 15.6716C13.8729 15.6716 17.7771 9.75665 17.7771 4.62665C17.7771 4.45998 17.7729 4.29165 17.7654 4.12498C18.5248 3.57579 19.1803 2.89573 19.7013 2.11665L19.7029 2.11415Z'
			fill='white'
		/>
	</svg>
)

export default TwitterIcon
