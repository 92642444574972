import { createSelector } from 'reselect';
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { StepNames } from 'src/enums';
import { ITransactionStatus } from 'src/@types/transactionStatus';

const initialState: Record<string, ITransactionStatus> = {}

export const DEFAULT_STEPS: Record<StepNames, { label: string }> = {
	UNSTARTED: { label: 'Swap has not been started'},
	SWITCH_NETWORKS: { label: 'Switching network' },
	APPROVE_TOKENS: { label: 'Approve in Wallet' },
	DEPOSIT_WETH: { label: 'Convert ETH to WETH' },
	CONFIRM_SWAP: { label: 'Confirm Swap' },
	MATCHING: { label: 'Matching order' },
}

export const historySlice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		updateHistory(state, action: PayloadAction<Record<string, any>>) {
			const { exchangeId } = action.payload
			if (!state[exchangeId]) {
				state[exchangeId] = {
					sellData: { value: '', networkId: 8453 },
					buyData: { value: '', networkId: 42161 },
					exchangeId: '',
					currentStep: StepNames.UNSTARTED,
					steps: {},
					visible: true,
				}
			}
			state[exchangeId] = { ...state[exchangeId], ...action.payload }
			return state
		},
		removeHistory(state, action: PayloadAction<string>) {
			const exchangeId = action.payload
			const newHistory = { ...state }
			delete newHistory[exchangeId]
			state = { ...newHistory }
			return state
		},
	},
})

const defaultTransactionStatus: ITransactionStatus = {
	sellData: { value: '', networkId: 43114 },
	buyData: { value: '', networkId: 42161 },
	exchangeId: '',
	currentStep: StepNames.UNSTARTED,
	steps: {},
	visible: true,
}

export const { updateHistory, removeHistory } = historySlice.actions

export const getOrder = createSelector(
	(state: RootState, exchangeId: string) => state.historySlice[exchangeId],
	(order) => order || defaultTransactionStatus
);

export default historySlice.reducer
