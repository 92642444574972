/* eslint-disable @typescript-eslint/no-unsafe-return */
import { cacheHalfFullApi } from 'src/libs/axios'

export const apiGetEstimateGasPrice = async (chain_name: string, lz_cid: number) => {
	try {
		const { data } = await cacheHalfFullApi.get(`/estimate_gas?chain_name=${chain_name.toLowerCase()}&lz_cid=${lz_cid}`)
		return data
	} catch (err) {
		throw new Error((err as Error).message)
	}
}
