// import node modules
import React, { useState, useMemo } from 'react'
import ReactLoading from 'react-loading'
import cn from 'classnames'
import OrderHistoryItemRow from './OrderHistoryItem'

export type OrderItemTableRow = {
	sellToken: IToken
	buyToken: IToken
	sellQuantity: number
	buyQuantity: number
	originChainId: string
	destChainId: string
	originChainTxHash: string
	destChainTxHash: string
}

type Props = {
	data: OrderItemTableRow[]
	loadingStatus: boolean
	search: string
}

type Order = 'asc' | 'desc'

function descendingComparator(a: OrderItemTableRow, b: OrderItemTableRow, orderBy: keyof OrderItemTableRow) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order: Order, orderBy: keyof OrderItemTableRow) {
	return order === 'desc'
		? (a: OrderItemTableRow, b: OrderItemTableRow) => descendingComparator(a, b, orderBy)
		: (a: OrderItemTableRow, b: OrderItemTableRow) => -descendingComparator(a, b, orderBy)
}

function stableSort(array: OrderItemTableRow[], comparator: (a: OrderItemTableRow, b: OrderItemTableRow) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [OrderItemTableRow, number])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const OrderHistoryTable: React.FC<Props> = ({ data, loadingStatus, search }) => {
	const [order, setOrder] = useState<Order>('desc')
	const [orderBy, setOrderBy] = useState<keyof OrderItemTableRow>('sellToken')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(50)

	const handleChangePage = (newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleRequestSort = (property: keyof OrderItemTableRow) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
  	}
	const visibleRows = useMemo(
		() => {
			return stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		},
		[page, rowsPerPage, data, order, orderBy]
	)

	return (
		<div className='relative h-[calc(100vh-300px)] max-h-[calc(100vh-330px)] w-full max-w-full overflow-auto'>
			<table className={cn('w-full', loadingStatus ? 'h-full' : '')}>
				<thead className='sticky top-0 z-30 bg-background'>
					<tr>
						<th className='px-2 py-4 text-left font-medium'>Sell</th>
						<th className='py-4'></th>{/*Contains the arrow */}
						<th className='px-2 py-4 text-left font-medium'>Buy</th>
						<th className='px-2 py-4 text-left font-medium'>Quantity Sold</th>
						<th className='px-2 py-4 text-left font-medium'>Quantity Received</th>
						<th className='px-2 py-4 text-left font-medium'>Origin Chain Tx Hash</th>
						<th className='px-2 py-4 text-left font-medium'>Destination Chain Tx Hash</th>
					</tr>
				</thead>
				<tbody>
					{loadingStatus && (
						<tr>
							<td colSpan={8}>
								<ReactLoading type='spin' height={25} width={25} className='mx-auto' />
							</td>
						</tr>
					)}
					{data.length === 0 && !loadingStatus && search != '' && (
						<tr>
							<td colSpan={8} className='py-4 text-center'>
								No orders found for the search term.
							</td>
						</tr>
					)}
					{!loadingStatus && data.length === 0 && search == '' && (
						<tr>
							<td colSpan={8} className='py-4 text-center'>
								No order history found.
							</td>
						</tr>
					)}
					{visibleRows.length > 0 &&
						visibleRows.map((item: OrderItemTableRow) => (
							<OrderHistoryItemRow key={`order-table-row-${item.originChainTxHash}`} item={item} />
						))}
				</tbody>
			</table>
		</div>
	)
}

export default OrderHistoryTable
