import { useAppDispatch, useAppSelector } from 'src/hooks/redux'
import { setCurrentModalId } from 'src/redux/slices/application.slice'
import { updateHistory } from 'src/redux/slices/history.slice'
import { formatNumber } from 'src/utils/formatNumber'
import CloseIcon from '../icons/Close'
import { ITransactionStatus } from 'src/@types/transactionStatus'

const TransactionStatusCard = (props: any) => {
	const { exchange, closeToast }: { exchange: ITransactionStatus; closeToast: any } = props
	const dispatch = useAppDispatch()
	const { availableNetworks } = useAppSelector((state) => state.configSlice)
	const { sellData, buyData } = exchange

	return (
		<div
			className='w-[360px] max-w-[calc(100%-24px)] rounded-md border border-modal-border/15 bg-modal-background'
			onClick={() => {
				dispatch(setCurrentModalId(exchange.exchangeId))
				closeToast()
			}}
		>
			<div className='mt-2 flex items-center justify-between border-b border-modal-border/10 px-4 py-2'>
				<span className='text-lg font-semibold'>Transaction Update</span>
				<CloseIcon
					className='cursor-pointer hover:opacity-70'
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						dispatch(updateHistory({ exchangeId: exchange.exchangeId, visible: false }))
						closeToast()
					}}
				/>
			</div>
			<div className='flex w-full flex-col py-4'>
				<div className='mx-auto mb-6 flex items-center'>
					<div className='relative mr-[12px]'>
						<img
							src={sellData.token?.logoURI}
							className='relative w-8 rounded-full shadow-[3px_3px_5px_0px_rgba(0,0,0,.55)] md:w-10'
						/>
						<img
							src={availableNetworks[sellData.networkId]?.icon}
							className='absolute -right-[12px] bottom-0 w-5 rounded-full shadow-[4px_2px_2px_0_rgba(0,0,0,.2)] md:w-6'
						/>
						<span className='absolute left-[35px] whitespace-nowrap font-bold italic md:text-lg'>{sellData.value}</span>
					</div>

					<img src='/images/arrow-right.png' alt='arrow' className='mx-6 w-12 animate-pulse' />

					<div className='relative mr-[12px]'>
						<img
							src={buyData.token?.logoURI}
							className='relative w-8 rounded-full shadow-[3px_3px_5px_0px_rgba(0,0,0,.55)] md:w-10'
						/>
						<img
							src={availableNetworks[buyData.networkId]?.icon}
							className='absolute -right-[12px] bottom-0 w-5 rounded-full shadow-[4px_2px_2px_0_rgba(0,0,0,.2)] md:w-6'
						/>
						<span className='absolute left-[35px] whitespace-nowrap font-bold italic md:text-lg'>
							{formatNumber(buyData.value)}
						</span>
					</div>
				</div>
				<span className='text-sm text-gray-600 mt-1 py-2 px-4'>
					This status card will disappear in a moment but we will continue processing your swap!
				</span>
			</div>
		</div>
	)
}

export default TransactionStatusCard
