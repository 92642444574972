import { ResultCode } from 'src/enums'
import { cacheHalfFullApi } from 'src/libs/axios'

export const apiSendOrderData = async (
	orderData: any,
	referrer?: string
): Promise<{ status: ResultCode; message: string; eta?: number; id?: string }> => {
	if (orderData === undefined) {
		return { message: 'Error decoding order data', status: ResultCode.failure }
	}
	const payload: any = {
		chain: orderData.sellChain,
		place_taker_tx: orderData.transactionHash,
	}

	if (referrer) {
		payload.referrer = referrer
	}

	try {
		const response = await cacheHalfFullApi.post('/v1/orders', payload)

		switch (response.status) {
			case 200:
				return {
					message: 'Order successfully sent.',
					status: ResultCode.success,
					eta: response.data.eta,
					id: response.data.id,
				}
			case 202:
				return {
					message: 'Order accepted but will take 5-20 minutes to find a match.',
					status: ResultCode.success,
					eta: response.data.eta,
				}
			case 400:
				return {
					message: 'Order will not be filled by the market maker at this time.',
					status: ResultCode.failure,
				}
			case 500:
				return { message: 'An unspecified error occurred.', status: ResultCode.failure }
			default:
				return { message: 'Unexpected error occurred.', status: ResultCode.failure }
		}
	} catch (error: any) {
		if (error?.response?.status === 400) {
			return {
				message: 'Error will not be filled by the market maker at this time.',
				status: ResultCode.failure,
			}
		} else {
			return {
				message: `Error sending order data to backend: ${(error as Error).message}`,
				status: ResultCode.failure,
			}
		}
	}
}

export const apiGetQuote = async (
    walletAddress: string,
    srcChain: string,
    dstChain: string,
    srcAssetAddress: string,
    dstAssetAddress: string,
    srcAmount: string,
    signal?: AbortSignal  // Add this parameter
): Promise<QuoteResponse> => {
    const payload = {
        wallet_address: {
            address: walletAddress,
        },
        src_chain: srcChain.toLowerCase(),
        dst_chain: dstChain.toLowerCase(),
        src_asset_address: {
            address: srcAssetAddress,
        },
        dst_asset_address: {
            address: dstAssetAddress,
        },
        src_amount: srcAmount,
    }

    try {
        // Pass the signal to the post method in case we want to abort the first and refetch a new one
        const response = await cacheHalfFullApi.post<QuoteResponse>('/v1/orders/quotes', payload, { signal })
        return response.data
    } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
            console.log('Request was aborted')
            throw error  // Re-throw the AbortError
        }
        console.error('Error fetching quote:', error)
        throw error
    }
}

export const apiGetMessageHexFromBurnTx = async (srcChain: string, burnTx: string): Promise<string> => {
	const { data } = await cacheHalfFullApi.get('/message_hex_from_burn_tx', {
		params: {
			src_chain: srcChain.toLowerCase(),
			burn_tx: burnTx,
		},
	})
	return data.message_hex as string
}

export const apiNewPostReceiveCctpMessage = async (
	placerAddress: string,
	srcChain: string,
	burnTx: string
): Promise<{ status: ResultCode; message: string; id?: string }> => {
	try {
		const response = await cacheHalfFullApi.post('/receive_cctp', {
			placer_address: {
				address: placerAddress,
			},
			src_chain_name: srcChain.toLowerCase(),
			src_burn_tx: burnTx,
		})

		if (response.status == 200 || response.status === 202) {
			return {
				message: 'Order accepted but will take 5-20 minutes to find a match.',
				status: ResultCode.success,
				id: response.data.id,
			}
		} else {
			return { message: 'Order will not be filled by the market maker at this time.', status: ResultCode.failure }
		}
	} catch (error: any) {
		return { message: `Error sending order data to backend: ${(error as Error).message}`, status: ResultCode.failure }
	}
}

export const apiPostReceiveCctpMessage = async (
	dstChain: string,
	messageHex: string
): Promise<{ status: ResultCode; message: string }> => {
	try {
		const response = await cacheHalfFullApi.post('/receive_cctp', {
			dst_chain_name: dstChain.toLowerCase(),
			message_hex: messageHex,
		})

		if (response.status == 200 || response.status === 202) {
			return { message: 'Order accepted but will take 5-20 minutes to find a match.', status: ResultCode.success }
		} else {
			return { message: 'Order will not be filled by the market maker at this time.', status: ResultCode.failure }
		}
	} catch (error: any) {
		return { message: `Error sending order data to backend: ${(error as Error).message}`, status: ResultCode.failure }
	}
}

export const apiGetOrderETA = async (
	srcChainName: string,
	dstChainName: string,
	srcAsset: string,
	dstAsset: string,
	amount: bigint
): Promise<number> =>
	// eslint-disable-next-line no-async-promise-executor
	new Promise(async (resolve, reject) => {
		try {
			const params = {
				src_chain_name: srcChainName,
				dst_chain_name: dstChainName,
				src_asset: srcAsset,
				dest_asset: dstAsset,
				amount: amount,
			}

			const queryString = Object.entries(params)
				.map(([key, value]) => `${key}=${value}`)
				.join('&')

			const { data }: { data: any } = await cacheHalfFullApi.get(`/orderETA?${queryString}`)

			if (data?.status_code === 200) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				resolve(data?.time as number)
			} else {
				reject()
			}
		} catch (err: any) {
			reject((err as Error).message)
		}
	})

export const apiGetOrderHistory = async (address: string) => {
	try {
		const { data }: { data: OrderItemResponse[] } = await cacheHalfFullApi.get(`/v1/orders/${address}`)

		return data
	} catch (error) {
		// Handle any errors that occur during the request
		console.error('Error fetching orders:', error)
		throw error
	}
}
