import cn from 'classnames'

interface Props {
	tokenLogo?: string
	networkIcon?: string
	className?: string
}

const ChainToken: React.FC<Props> = ({ tokenLogo, networkIcon, className }: Props) => {
	return (
		<div className={cn('relative flex items-end', className)}>
			<img src={tokenLogo} className='h-6 w-6 shrink rounded-full sm:h-[34px] sm:w-[34px]' />
			<img src={networkIcon} className='absolute -right-[6px] h-4 w-4 rounded-full sm:h-5 sm:w-5' />
		</div>
	)
}

export default ChainToken
