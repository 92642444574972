import { SVGProps } from 'react'

const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='18'
		height='16'
		viewBox='0 0 18 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M1.32168 6.65361L16.7484 0.70561C17.4644 0.446943 18.0897 0.880277 17.8577 1.96294L17.859 1.96161L15.2323 14.3363C15.0377 15.2136 14.5164 15.4269 13.787 15.0136L9.78702 12.0656L7.85768 13.9243C7.64435 14.1376 7.46435 14.3176 7.05102 14.3176L7.33502 10.2469L14.7484 3.54961C15.071 3.26561 14.6763 3.10561 14.251 3.38828L5.08968 9.15628L1.14035 7.92428C0.283017 7.65228 0.26435 7.06694 1.32168 6.65361Z'
			fill='white'
		/>
	</svg>
)

export default TelegramIcon
