import { useEffect, useState, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { MetaMaskAvatar } from 'react-metamask-avatar'
import cn from 'classnames'
import { useAppDispatch, useAppSelector } from 'src/hooks/redux'
import useBalances from 'src/hooks/useBalances'
import { getNativeTokenPrices } from 'src/redux/slices/price.slice'
import { formatNumber } from 'src/utils/formatNumber'
import { useAccount } from 'wagmi'
import ChainToken from '../ChainToken'
import ConnectButton from '../buttons/ConnectButton'
import LogoutIcon from '../icons/Logout'
import NoWalletIcon from '../icons/NoWallet'

interface Props {
	open: boolean
	onClose: () => void
	onLogout: () => void
}

const WalletPortfolioDrawer: React.FC<Props> = (props: Props) => {
	const { open, onClose, onLogout } = props
	const { address, isConnected } = useAccount()
	const { balances, isLoading, totalBalance } = useBalances()
	const [internalOpen, setInternalOpen] = useState<boolean>(false)
	const dispatch = useAppDispatch()
	const availableNetworks = useAppSelector((state) => state.configSlice.availableNetworks)

	useEffect(() => {
		if (isConnected) {
			dispatch(getNativeTokenPrices(availableNetworks))
		}
	}, [isConnected, availableNetworks, dispatch])

	useEffect(() => {
		setInternalOpen(open)
	}, [open])

	const renderBalancesIfTotalBalanceGreaterThanZero = () => {
		if (totalBalance > 0)
			return balances.map((token, _id) => (
				<div
					key={`chain-token-${token.networkId}-${token.symbol}-${_id}`}
					className='flex w-full cursor-pointer items-center space-x-3 px-4 py-2 transition-all duration-500 hover:bg-modal-border/20'
				>
					<ChainToken networkIcon={token.networkLogo} tokenLogo={token.tokenLogo} />
					<div className='text-sm'>
						<p>{token.name}</p>
						<p className='text-sub-text'>
							{formatNumber(token.balance)} {token.symbol}
						</p>
					</div>
					<p className='!ml-auto text-sm font-semibold'>$ {(token.price * token.balance).toFixed(2)}</p>
				</div>
			))
		else
			return (
				<div className='margin-auto'>
					<NoWalletIcon style={{ margin: 'auto' }} />
					<div className='space-y-3 text-center'>
						<p className='text-xl font-semibold'>No balances found</p>
						<span className='text-xs font-medium'>Sure you&apos;ve got the right wallet?</span>
					</div>
				</div>
			)
	}

	const renderLoader = () => {
		return Array(5)
			.fill(0)
			.map((_, id) => (
				<div key={`skeleton-${id}`} className='flex w-full items-center space-x-3 px-4 py-2'>
					<Skeleton enableAnimation baseColor='#9B9B9B' circle className='h-9 w-9' />
					<div>
						<Skeleton enableAnimation baseColor='#9B9B9B' className='w-20' />
						<Skeleton enableAnimation baseColor='#9B9B9B' className='w-20' />
					</div>
					<Skeleton enableAnimation baseColor='#9B9B9B' className='w-20' containerClassName='!ml-auto' />
				</div>
			))
	}

	return (
		<div
			className={cn(
				'fixed bottom-2 right-2 z-50 h-[calc(100%-72px)] overflow-hidden md:h-[calc(100%-16px)]',
				open ? 'opacity-100' : 'pointer-events-none opacity-0'
			)}
		>
			<div
				className='fixed bottom-0 left-0 right-0 top-0 z-10 cursor-pointer bg-black/50 backdrop-blur-sm'
				onClick={() => {
					setInternalOpen(false)
					setTimeout(() => onClose(), 300)
				}}
			></div>
			<div
				className={cn(
					'relative z-20 h-full w-[390px] max-w-[390px] space-y-3 overflow-auto rounded-md border border-modal-border/50 bg-modal-background px-4 py-5 transition-all duration-300',
					internalOpen ? 'translate-0' : 'translate-y-full md:translate-x-full md:translate-y-0'
				)}
				style={{
					boxShadow:
						'rgba(0, 0, 0, 0.24) 12px 16px 24px, rgba(0, 0, 0, 0.24) 12px 8px 12px, rgba(0, 0, 0, 0.32) 4px 4px 8px',
				}}
			>
				{isConnected ? (
					<>
						<div className='flex items-center justify-between'>
							<div className='flex gap-2.5'>
								{address && typeof address === 'string' && address.startsWith('0x') && (
									<MetaMaskAvatar address={address} size={20} />
								)}
								<p className='text-sm'>
									{address?.substring(0, 6)}...{address?.slice(address.length - 4, address.length)}
								</p>
							</div>
							<button onClick={onLogout}>
								<LogoutIcon />
							</button>
						</div>
						<h3 className='!mt-5 text-4xl font-bold'>
							{isLoading ? (
								<Skeleton enableAnimation baseColor='#9B9B9B' className='h-10 w-20' />
							) : (
								<>$ {Number(formatNumber(totalBalance)).toFixed(2)}</>
							)}
						</h3>
						<div className='-mx-4 !mt-6'>
							{isLoading ? renderLoader() : renderBalancesIfTotalBalanceGreaterThanZero()}
						</div>
					</>
				) : (
					<div className='flex h-full flex-col items-center justify-center gap-4'>
						<p className='text-center'>Connect your wallet to see your multichain token balances</p>
						<ConnectButton />
					</div>
				)}
			</div>
		</div>
	)
}

export default WalletPortfolioDrawer
