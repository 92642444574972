import { useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { CACHE_HALF_FULL_URL } from 'src/configs/env'
import { updateHistory } from 'src/redux/slices/history.slice'
import { getBlockExplorerUrlFromId } from 'src/utils/getBlockExplorerUrl'
import { useAppDispatch, useAppSelector } from './redux'
import { ResultCode, StepNames } from 'src/enums'

type LiveOrderData = {
	id: string
	taker_address: string
	maker_address: string
	src_chain: string
	dst_chain: string
	src_asset_address: string
	dst_asset_address: string
	bond_asset_address: string
	src_amount: number
	dst_amount: number
	bond_amount: number
	created_at: Date
	expiration: Date
	status: 'queued' | 'filling' | 'filled' | 'completed'
	completed: boolean
	place_tx: string
	match_tx: string | null
	fill_tx: string | null
	eta: number
}

export const useOrderUpdates = (wallet: string, orderId: string, exchangeId: string) => {
	const dispatch = useAppDispatch()
	const { availableNetworks } = useAppSelector((state) => state.configSlice)

	const wssBaseUrl = CACHE_HALF_FULL_URL?.replace('https', 'wss')
	const wssUrlEndpoint = (wssBaseUrl && wallet && orderId) ? `${wssBaseUrl}/v1/orders/${wallet}/${orderId}` : null
	const { sendMessage, lastMessage, readyState } = useWebSocket(wssUrlEndpoint, {
		shouldReconnect: () => true, // Automatically reconnect on disconnect
		reconnectAttempts: 10, // Number of attempts to reconnect
		reconnectInterval: 3000, // Time in milliseconds between reconnection attempts
	})
	const [orderData, setOrderData] = useState<LiveOrderData | null>(null)

	useEffect(() => {
		if (lastMessage !== null) {
			try {
				// First parse to decode the outer string
				const firstParsedData = JSON.parse(lastMessage.data)
				// Second parse to decode the inner JSON string
				const data: LiveOrderData = JSON.parse(firstParsedData)
				setOrderData(data)

				const sourceChain = Object.entries(availableNetworks).find(
					(chain) => chain[1].name.toLowerCase() === data.src_chain.toLowerCase()
				)
				const destinationChain = Object.entries(availableNetworks).find(
					(chain) => chain[1].name.toLowerCase() === data.dst_chain.toLowerCase()
				)
                
                const sourceTxHash = `${getBlockExplorerUrlFromId(sourceChain?.[0] || '')}/${data.place_tx}` as `0x${string}`
                const destinationTxHash = data.fill_tx ? `${getBlockExplorerUrlFromId(destinationChain?.[0] || '')}/${data.fill_tx}` as `0x${string}` : undefined

				const updateData = {
					exchangeId,
					currentStep: StepNames.MATCHING,
					result: {
						sourceTxHash: sourceTxHash,
						destinationTxHash: destinationTxHash,
						status: data.completed ? ResultCode.success : ResultCode.pending,
						message: '',
						transactionETA: data.eta,
					},
				}

				const estimatedTimeMessage =
					data.eta > 60
						? `Order is expected to match in approximately ${(data.eta / 60).toFixed(2)} minutes.`
						: `Order is expected to match in approximately ${data.eta} seconds.`
				updateData.result.message = estimatedTimeMessage

				dispatch(updateHistory(updateData))
			} catch (error) {
				console.error('Failed to parse message:', error)
			}
		}
	}, [lastMessage])

	return { sendMessage, lastMessage, readyState, orderData }
}
