import { useContext } from 'react'
import cn from 'classnames'
import { useAppDispatch, useAppSelector } from 'src/hooks/redux'
import { openWalletDrawer } from 'src/redux/slices/application.slice'
import { truncateAddress } from 'src/utils/truncateAddress'
import { useAccount } from 'wagmi'
import { AuthProviderContext } from '../../providers/AuthProvider'
import WalletIcon from '../icons/Wallet'

const ConnectButton = ({
	className,
	...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
	const dispatch = useAppDispatch()
	const { connectHandler } = useContext(AuthProviderContext)
	const { address, chainId, isConnected } = useAccount()
	const { availableNetworks } = useAppSelector((state) => state.configSlice)

	return (
		<>
			{!isConnected ? (
				<button
					className={cn(
						'flex items-center justify-center space-x-[10px] rounded-2xl bg-swap-new px-[18px] py-[14px] font-medium leading-7 hover:brightness-110',
						className
					)}
					onClick={connectHandler}
					{...props}
				>
					<WalletIcon />
					<span>Connect Wallet</span>
				</button>
			) : (
				<button
					className={cn(
						'flex items-center justify-center space-x-[10px] rounded-2xl bg-[#2c2c2c] px-[18px] py-[14px] font-medium'
					)}
					onClick={() => dispatch(openWalletDrawer(true))}
				>
					{availableNetworks[chainId || 0]?.icon && (
						<img src={availableNetworks[chainId || 0]?.icon} alt='network' className='w-5' />
					)}
					<span>{truncateAddress(address ?? '')}</span>
				</button>
			)}
		</>
	)
}

export default ConnectButton
