export const DAPP_ENVIRONMENT = process.env.REACT_APP_DAPP_ENV || 'mainnet'
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || ''
export const CACHE_HALF_FULL_URL = (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'prod':
        case 'production':
            return process.env.REACT_APP_CACHE_HALF_FULL_PROD;
        case 'staging':
            return process.env.REACT_APP_CACHE_HALF_FULL_STAGING;
        case 'dev':
        default:
            return process.env.REACT_APP_CACHE_HALF_FULL_DEV;
    }
})();
