import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from './redux'

export const useParseSearchParams = () => {
	const [searchParams] = useSearchParams()
	const { availableTokens, availableNetworks } = useAppSelector((state) => state.configSlice)

	const src_chain = searchParams.get('src_chain')
	const src_token = searchParams.get('src_token')
	const dst_chain = searchParams.get('dst_chain')
	const dst_token = searchParams.get('dst_token')

	const sellNetworkId = useMemo(() => {
		return (
			Object.keys(availableNetworks).find(
				(networkId) => availableNetworks[networkId].abbreviation.toLowerCase() === src_chain?.toLowerCase()
			) || undefined
		)
	}, [availableNetworks, src_chain])

	const buyNetworkId = useMemo(() => {
		return (
			Object.keys(availableNetworks).find(
				(networkId) => availableNetworks[networkId].abbreviation.toLowerCase() === dst_chain?.toLowerCase()
			) || undefined
		)
	}, [availableNetworks, dst_chain])

	const sellToken = useMemo(() => {
		if (!sellNetworkId) return undefined

		return (
			availableTokens.find(
				(token) => +token.chainId === +sellNetworkId && token.symbol.toLowerCase() === src_token?.toLowerCase()
			) || undefined
		)
	}, [availableTokens, sellNetworkId, src_token])

	const buyToken = useMemo(() => {
		if (!buyNetworkId) return undefined

		return (
			availableTokens.find(
				(token) => +token.chainId === +buyNetworkId && token.symbol.toLowerCase() === dst_token?.toLowerCase()
			) || undefined
		)
	}, [availableTokens, buyNetworkId, dst_token])

	return { sellNetworkId, buyNetworkId, sellToken, buyToken }
}
