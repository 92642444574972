// import node modules
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'
import { toast as modal } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import cn from 'classnames'
import { apiGetQuote } from 'src/api/order.api'
import TokenSelectionPill from 'src/components/TokenSelectionPill'
import TransactionStatusCard from 'src/components/TransactionStatusCard'
import ConnectButton from 'src/components/buttons/ConnectButton'
import SwapButton from 'src/components/buttons/SwapButton'
import ChevronDownIcon from 'src/components/icons/ChevronDown'
import SortIcon from 'src/components/icons/Sort'
import ReviewSwapModal from 'src/components/modals/ReviewSwapModal'
import { SUPPORTED_TOKENS_ON_GO_STACK } from 'src/configs/golangStack'
import { MAX_AMOUNT_GO_STACK, MAX_AMOUNT_PYTHON_STACK } from 'src/configs/numbers'
import { useAppDispatch, useAppSelector } from 'src/hooks/redux'
import { useExchangeToken } from 'src/hooks/useExchangeToken'
import { useExchangeType } from 'src/hooks/useExchangeType'
import { useFee } from 'src/hooks/useFee'
import { useParseSearchParams } from 'src/hooks/useParseSearchParams'
import { useSupportedChain } from 'src/hooks/useSupportedChain'
import { useTokenBalance } from 'src/hooks/useTokenBalance'
import { setBuyData, setCurrentModalId, setExchangeId, setSellData } from 'src/redux/slices/application.slice'
import { getOrder, updateHistory } from 'src/redux/slices/history.slice'
import { formatBalance, formatBalanceString, formatUnits } from 'src/utils/formatBalance'
import { formatNumber } from 'src/utils/formatNumber'
import { truncateAddress } from 'src/utils/truncateAddress'
import { v4 as uuidv4 } from 'uuid'
import { useAccount } from 'wagmi'
import BigNumber from 'bignumber.js'
import { StepNames } from 'src/enums'

const ExchangePage = () => {
	const [searchParams] = useSearchParams()
	const dispatch = useAppDispatch()
	const { isConnected, chainId, address } = useAccount()
	const { isValidChain } = useSupportedChain()

	const { sellNetworkId, sellToken, buyNetworkId, buyToken } = useParseSearchParams()
	const { availableTokens, availableNetworks } = useAppSelector((state) => state.configSlice)
	const { sellData, buyData, exchangeId, currentModalId } = useAppSelector((state) => state.applicationSlice)
	const exchangeInfoForModal = useAppSelector((state) => getOrder(state, currentModalId))
	const exchanges = useAppSelector((state) => state.historySlice)

	const [isGasFeeExpanded, setIsGasFeeExpanded] = useState<boolean>(false)
	const transactionData = useAppSelector((state) => getOrder(state, exchangeId))

	const { estimatedGasPrice, exchange } = useExchangeToken(exchangeId)

	const { balance, isBalanceLoading } = useTokenBalance(
		+sellData.networkId,
		sellData.token?.wrapped ? undefined : sellData.token?.address || '',
		address || ''
	)
	const exchangeType = useExchangeType(sellData, buyData)
	const machFee = useFee(sellData, exchangeType, availableNetworks[sellData.networkId].name)
	const [buyValue, setBuyValue] = useState<string>('')
	const [isBuyOutputLoading, setIsBuyOutputLoading] = useState<boolean>(false)
    const [isQuoteInvalid, setIsQuoteInvalid] = useState<boolean>(false)

	const insufficientBalance = useMemo(() => {
		return formatBalance(balance) < +(sellData.value ?? 0)
	}, [balance, sellData])

	useEffect(() => {
		let abortController = new AbortController()

		const fetchQuote = async () => {
			if (!sellData.value || +sellData.value === 0) {
                console.log("setting null buy data") // TODO: This is triggered sometimes when it shouldn't
				setBuyValue('')
				setIsBuyOutputLoading(false)
                setIsQuoteInvalid(false)
				return
			}
			if (!sellData.token || !buyData.token) {
				setIsBuyOutputLoading(false)
				return
			}

			// Set loading state immediately
			setIsBuyOutputLoading(true)

			// Abort any ongoing request
			abortController.abort()
			// Create a new controller for the new request
			abortController = new AbortController()

			const sourceChainName = availableNetworks[sellData.networkId].name
			const destChainName = availableNetworks[buyData.networkId].name

			try {
				// Introduce a small delay to ensure the loading state is visible
				await new Promise(resolve => setTimeout(resolve, 25))

                const sellValue = new BigNumber(sellData.value)
                const safeSrcAmount = sellValue.multipliedBy(new BigNumber(10).pow(sellData.token?.decimals || 0))
		
				const quoteData = await apiGetQuote(
                    address?.toString() || '',
                    sourceChainName,
                    destChainName,
                    sellData.token?.address,
                    buyData.token?.address,
                    safeSrcAmount.toString(),
                    abortController.signal
				)

				const formattedBuyValue = formatUnits(BigInt(quoteData.dst_amount), buyData.token?.decimals)
				console.log('Formatted buy value:', formattedBuyValue)
				setBuyValue(formattedBuyValue)

                const quoteIsInvalid = Boolean(quoteData.invalid_amount) || formattedBuyValue === "0"
                setIsQuoteInvalid(quoteIsInvalid)
		  	} catch (error: unknown) {
				if (error instanceof DOMException && error.name === 'AbortError') {
					console.log('Request was aborted')
				} else {
					console.error('Failed to fetch quote:', error)
					setBuyValue('')
				}
			} finally {
				// Only set loading to false if this is still the active controller
				if (!abortController.signal.aborted) {
					setIsBuyOutputLoading(false)
				}
			}
		}

		fetchQuote()

		// Cleanup function to cancel the fetch if the component unmounts or dependencies change
		return () => {
			abortController.abort()
		}
	}, [sellData, buyData.token])

	const shortenAddress = useMemo(() => {
		return truncateAddress(address ?? '')
	}, [address])

	const { estimatedGasPrice: estimatedGasPriceForModal } = useExchangeToken(currentModalId)
	const exchangeTypeForModal = useExchangeType(exchangeInfoForModal.sellData, exchangeInfoForModal.buyData)
	const machFeeForModal = useFee(
		exchangeInfoForModal.sellData,
		exchangeTypeForModal,
		availableNetworks[sellData.networkId].name
	)
	const feesForModal = useMemo(() => {
		return [
			{ label: 'Gas Fee', value: estimatedGasPriceForModal },
			{ label: 'Mach Fee', value: machFee },
		]
	}, [estimatedGasPriceForModal, machFeeForModal])

	const disabledTokens = useMemo(() => {
		if (!sellData.token) {
			return []
		}

		let _disabledTokens: IToken[] = []

		// Disable Scroll USDC -> USDC on any chain
		if (+sellData.networkId === 534352 && sellData.token.symbol === 'USDC') {
			_disabledTokens = _disabledTokens.concat(availableTokens.filter((token) => token.symbol === 'USDC'))
		}

		// Disable Eth -> anything thats not USDC and is not on the Binance stack
		if (+sellData.networkId === 1) {
			_disabledTokens = _disabledTokens.concat(
				availableTokens.filter(
					(token) => token.symbol !== 'USDC' && !SUPPORTED_TOKENS_ON_GO_STACK.includes(token.address)
				)
			)
		}

		// Disable anything thats not USDC and is not on the Binance stack -> ETH
		if (sellData.token.symbol !== 'USDC' && !SUPPORTED_TOKENS_ON_GO_STACK.includes(sellData.token.address)) {
			_disabledTokens = _disabledTokens.concat(availableTokens.filter((token) => token.chainId === 1))
		}

		// Disable Celo or BSC assets -> anything that isnt on the binnace stack
		if (+sellData.networkId === 42220 || +sellData.networkId === 56) {
			_disabledTokens = _disabledTokens.concat(
				availableTokens.filter((token) => !SUPPORTED_TOKENS_ON_GO_STACK.includes(token.address))
			)
		}

		// Disable any asset that isnt on the binance stack -> Celo or BSC
		if (!SUPPORTED_TOKENS_ON_GO_STACK.includes(sellData.token.address)) {
			_disabledTokens = _disabledTokens.concat(
				availableTokens.filter((token) => token.chainId === 42220 || token.chainId === 56)
			)
		}

		return _disabledTokens
	}, [sellData])

	const disabledChains = useMemo(() => {
		// Disable the origin chain as a destination
		const _disabledChains = [+sellData.networkId]

		// Disable any chains for which all the tokens are disabled
		Object.keys(availableNetworks).forEach((networkId) => {
			const availableCurTokens = availableTokens.filter((token) => +token.chainId === +networkId)
			const disabledCurTokens = disabledTokens.filter((token) => +token.chainId === +networkId)
			if (availableCurTokens.length === disabledCurTokens.length) {
				_disabledChains.push(+networkId)
			}
		})

		return _disabledChains
	}, [sellData, disabledTokens])

	useEffect(() => {
		dispatch(setBuyData({ value: buyValue }))
	}, [buyValue])

	useEffect(() => {
		if (!availableNetworks || !availableTokens.length) {
			return
		}

		if (!sellData.networkId || Object.keys(availableNetworks).findIndex((nId) => +nId === +sellData.networkId) < 0) {
			dispatch(setSellData({ networkId: isValidChain && chainId ? +chainId : +Object.keys(availableNetworks)[0] }))
			return
		}

		const availableSellTokens = availableTokens.filter((token) => +token.chainId === +sellData.networkId)

		if (!availableSellTokens.length) {
			return
		}

		if (
			!sellData.token ||
			availableSellTokens.findIndex((token) => token.symbol === sellData.token?.symbol) < 0 ||
			+sellData.token?.chainId !== +sellData.networkId
		) {
			// setSellData((prev) => ({ ...prev, token: tokens[0] }))
			dispatch(
				setSellData({
					token:
						availableSellTokens.find((token) => token.symbol === sellData.token?.symbol) ||
						availableSellTokens.find((token) => token.symbol === 'USDC') ||
						availableSellTokens[0],
				})
			)
		}

		if (!buyData.networkId) {
			return
		}

		const availableBuyNetworks = Object.keys(availableNetworks).filter(
			(networkId) => disabledChains.findIndex((dId) => +dId === +networkId) < 0
		)

		const buyNetworkId =
			availableBuyNetworks.findIndex((networkId) => +networkId === +buyData.networkId) >= 0
				? +buyData.networkId
				: availableBuyNetworks[0]

		const availableBuyTokens = availableTokens
			.filter((token) => +token.chainId === +buyNetworkId)
			.filter(
				(token) =>
					disabledTokens.findIndex((dToken) => +dToken.chainId === +buyNetworkId && dToken.symbol === token.symbol) < 0
			)

		const buyToken =
			buyData.token &&
			availableBuyTokens.findIndex(
				(token) => +token.chainId === +(buyData.token?.chainId || 0) && token.symbol === buyData.token?.symbol
			) >= 0
				? buyData.token
				: availableBuyTokens.find((token) => token.symbol === 'USDC') || availableBuyTokens[0]

		if (
			+buyNetworkId !== +buyData.networkId ||
			buyToken.symbol !== buyData.token?.symbol ||
			+buyToken.chainId !== +buyData.token.chainId
		) {
			dispatch(
				setBuyData({
					networkId: buyNetworkId,
					token: buyToken,
				})
			)
		}
	}, [sellData, buyData, availableTokens, availableNetworks, disabledTokens, disabledChains])

	useEffect(() => {
		if (sellNetworkId && sellToken) {
			dispatch(setSellData({ ...sellData, networkId: sellNetworkId, token: sellToken }))
		}

		if (buyNetworkId && buyToken) {
			dispatch(setBuyData({ ...buyData, networkId: buyNetworkId, token: buyToken }))
		}
	}, [sellNetworkId, sellToken, buyNetworkId, buyToken])

	const onCloseReviewSwapModal = () => {
		const isLargeScreen = window.innerWidth >= 600 // Define the threshold for a large screen (e.g., 1024px)
		if (exchanges[currentModalId].result?.status !== 'completed' && exchanges[currentModalId].currentStep !== StepNames.UNSTARTED) {
			modal((modalProps) => <TransactionStatusCard exchange={exchanges[currentModalId]} {...modalProps} />, {
				hideProgressBar: false,
				autoClose: 5000,
				position: isLargeScreen ? 'bottom-right' : 'bottom-center', // Use 'bottom-center' for small screens
				toastId: currentModalId,
				pauseOnHover: true,
			})
		}
		dispatch(setCurrentModalId(''))
	}

	const isPotentiallyCCTP = () => {
		return (
			sellData.token?.symbol === 'USDC' && buyData.token?.symbol === 'USDC' && sellData.networkId !== buyData.networkId
		)
	}

	const isPotentiallyGoStack = () => {
		return Boolean(
			sellData.token?.address 
			&& buyData.token?.address
			&& SUPPORTED_TOKENS_ON_GO_STACK.includes(sellData.token?.address)
			&& SUPPORTED_TOKENS_ON_GO_STACK.includes(buyData.token?.address)
		)
	}

	const onChangeSellValue = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
		const regex = /^\d*\.?\d{0,6}$/
		if (!isPotentiallyCCTP()) {
			const maximum = isPotentiallyGoStack() ? MAX_AMOUNT_GO_STACK : MAX_AMOUNT_PYTHON_STACK
			if (newValue === '' || (regex.test(newValue) && +newValue <= maximum)) {
				dispatch(setSellData({ value: newValue }))
			}
		} else {
			if (newValue === '' || regex.test(newValue)) {
				dispatch(setSellData({ value: newValue }))
			}
		}
	}

	const onChangeExchangeData = (__newData: IExchangeData, __type: 'buy' | 'sell' = 'sell') => {
		if (__type === 'sell') {
			const currentToken = sellData.token
			const currentNetworkId = sellData.networkId

			// Check if the token is different from the current token
			const isDifferentToken = __newData.token && currentToken && __newData.token.symbol !== currentToken.symbol
			// Check if the network is different from the current network
			const isDifferentChain = __newData.networkId && currentNetworkId && +__newData.networkId !== +currentNetworkId

			if (isDifferentToken || isDifferentChain) {
				__newData.value = 0
			}
		}

		if (__type === 'buy') {
			const previousTokenName = buyData.token?.name
			const targetChainIdTokens = availableTokens.filter((token) => token.chainId === __newData.networkId)
			if (__newData.networkId !== buyData.networkId) {
				__newData.token = targetChainIdTokens.find((token) => token.name === previousTokenName)
			}
			dispatch(setBuyData(__newData))
		} else {
			try {
				dispatch(setSellData({ ...__newData }))
			} catch (err) {
				console.error(err)
				throw err
			}
		}
	}

	const onSwapCurrency = () => {
		const [token1, token2] = [sellData.token, buyData.token]
		const [networkId1, networkId2] = [sellData.networkId, buyData.networkId]
		try {
			dispatch(setSellData({ networkId: networkId2, token: token2, value: '' }))
			dispatch(setBuyData({ networkId: networkId1, token: token1, value: '' }))
		} catch (err) {
			console.error(err)
		}
	}

	const onConfirmExchange = () => {
		const newExchangeId = uuidv4()
		dispatch(updateHistory({ exchangeId: newExchangeId, buyData, sellData }))
		dispatch(setExchangeId(newExchangeId))
		dispatch(setCurrentModalId(newExchangeId))
	}

	const exchangeTokenHandler = async () => {
		try {
			await exchange(searchParams.get('referrer') ?? undefined)
		} catch (err) {
			const errorMessagesToIgnore = [
				'User rejected the request.',
				'MetaMask Tx Signature: User denied transaction signature.',
			]
			if (!(err as any).message || !(err as any).shortMessage) toast.error('Error occurred')
			const errorMessage = (
				((err as any).shortMessage as string) ||
				((err as any).message as string) ||
				'Error occurred'
			).split('\n')[0]
			if (!errorMessage || errorMessagesToIgnore.includes(errorMessage)) return
			else toast.error(errorMessage)
		}
	}

	useEffect(() => {
		Object.entries(exchanges).forEach(([__exchangeId, value]) => {
			if (value.result && value.visible) {
				modal.dismiss(__exchangeId)
				dispatch(updateHistory({ exchangeId: value.exchangeId, visible: false }))
			}
		})
	}, [exchanges])

	const setMax = () => {
		if (!isPotentiallyCCTP()) {
			const maximum = isPotentiallyGoStack() ? MAX_AMOUNT_GO_STACK : MAX_AMOUNT_PYTHON_STACK
			dispatch(setSellData({ value: Math.min(+formatBalanceString(balance), maximum) }))
		} else {
			dispatch(setSellData({ value: +formatBalanceString(balance) }))
		}
	}

	return (
		<>
			<div className='mt-5 w-[480px] max-w-[calc(100%-24px)] rounded-2xl border border-modal-border/30 bg-modal-background p-4 md:mt-10 lg:mt-20'>
				<div className='space-y-2'>
					<div className='flex items-center space-x-2'>
						<div>
							<h4 className='flex items-center gap-2 text-sm font-semibold leading-[22px]'>
								Send
								{sellData.token?.wrapped && (
									<span
										className='flex h-4 w-4 items-center justify-center rounded-full bg-primary/50 p-2 text-[10px] text-white'
										data-tooltip-id='unwrapped-token-notification'
									>
										!
									</span>
								)}
							</h4>
							<input
								className='w-full bg-transparent text-[26px] font-semibold'
								placeholder='0'
								value={sellData.value}
								onChange={onChangeSellValue}
							/>
							<span className='mt-1 flex items-center text-xs font-medium text-sub-text'>
								{isConnected && (
									<>
										{isBalanceLoading ? (
											<Skeleton containerClassName='w-24' enableAnimation baseColor='#9B9B9B' />
										) : (
											<>
												<span>Balance: {formatBalance(balance).toFixed(3)}</span>
												<button className='ml-1 text-highlight-text hover:underline' onClick={setMax}>
													Max
												</button>
											</>
										)}
									</>
								)}
							</span>
						</div>
						<TokenSelectionPill
							onChange={(_d) => onChangeExchangeData(_d, 'sell')}
							value={sellData}
							title='Send from'
						/>
					</div>

					<div className='flex w-full items-center space-x-5'>
						<span className='h-[1px] flex-1 bg-[rgba(155,155,155,.2)]' />
						<button className='rounded-full transition-all hover:bg-white/5' onClick={onSwapCurrency}>
							<SortIcon className='text-[#fff]' />
						</button>
						<span className='h-[1px] flex-1 bg-[rgba(155,155,155,.2)]' />
					</div>

					<div className='flex items-center space-x-2'>
						<div>
							<h4 className='text-sm font-semibold leading-[22px]'>Receive</h4>
							<input
								className='w-full bg-transparent text-[26px] font-semibold'
								placeholder={isBuyOutputLoading ? 'Loading...' : (isQuoteInvalid || +(sellData.value || 0) !== 0 ? 'Unable to fill' : '0')}
								value={isBuyOutputLoading || isQuoteInvalid ? '' : formatNumber(buyValue)}
								disabled
							/>
							{isConnected && (
								<span className='mt-1 block text-xs font-medium text-sub-text'>
									Destination wallet: <a className='text-highlight-text'>{shortenAddress}</a>
								</span>
							)}
						</div>
						<TokenSelectionPill
							onChange={(_d) => onChangeExchangeData(_d, 'buy')}
							value={buyData}
							title='Receive on'
							disabledChains={disabledChains}
							disabledTokens={disabledTokens}
						/>
					</div>
				</div>

				<div className='mt-7 space-y-2'>
					{!isConnected && <ConnectButton className='w-full' />}
					{isConnected && (
						<SwapButton
							className='w-full'
							insufficientBalance={insufficientBalance}
                            isQuoteInvalid={isQuoteInvalid}
							sellData={sellData}
							onClick={() => onConfirmExchange()}
						/>
					)}

					<div className='flex flex-col text-xs font-medium'>
						{+(buyData.value || '0') !== 0 && (
                            <div className='flex justify-between px-[2px] py-1'>

                                <span>
                                    1 {sellData.token?.symbol} = {formatNumber(+(buyData.value || 1) / (+(sellData.value || 1) || 1))}{' '}
                                    {buyData.token?.symbol}
                                </span>
                                    <div className='flex cursor-pointer items-center' onClick={() => setIsGasFeeExpanded((prev) => !prev)}>
                                        {!isGasFeeExpanded && (
                                            <p>
                                                Output: {formatNumber(buyValue)} {buyData.token?.symbol}
                                            </p>
                                        )}
                                        <ChevronDownIcon className={cn('h-4 w-4 transition-all', isGasFeeExpanded ? 'rotate-180' : '')} />
                                    </div>
                            </div>
						)}

						<div
							className={cn(
								'flex flex-col space-y-[6px] overflow-hidden transition-all duration-500',
								isGasFeeExpanded ? 'h-[60px]' : 'h-0'
							)}
						>
							<>
								<div className='flex justify-between'>
									<span className='text-sub-text'>Gas fees</span>
									<span className='text-sub-text'>
										{+formatNumber(estimatedGasPrice) < 0.01 ? '<$0.01' : `$${formatNumber(estimatedGasPrice)}`}
									</span>
								</div>
								<div className='flex justify-between'>
									<span className='text-sub-text'>Mach fees</span>
									<span className='text-sub-text'>
										{+formatNumber(machFee) < 0.01 ? '<$0.01' : `$${formatNumber(machFee)}`}
									</span>
								</div>
								<div className='flex justify-between'>
									<span className='text-sub-text'>Total Output</span>
									<span className='text-sub-text'>${formatNumber(buyValue)}</span>
								</div>
							</>
						</div>
					</div>
				</div>
			</div>
			{transactionData && (
				<ReviewSwapModal
					isOpen={!!currentModalId}
					onClose={onCloseReviewSwapModal}
					onConfirm={exchangeTokenHandler}
					fees={feesForModal}
					{...transactionData}
				/>
			)}
			<Tooltip
				id='unwrapped-token-notification'
				style={{ zIndex: 1000, maxWidth: '300px' }}
				render={() => (
					<p className='text-xs'>
						Here you will be swapping with the wrapped version of the gas token. We will prompt you to sign a request to
						convert your gas into the wrapped token after you initiate the swap
					</p>
				)}
			/>
		</>
	)
}

export default ExchangePage
