// How to display the chain names on the users' screens
export const chainNames: { [key: string]: string } = {
  arbitrum: "Arbitrum",
  avalanche: "Avalanche",
  celo: "Celo",
  base: "Base",
  blast: "Blast",
  bsc: "BSC",
  ethereum: "Ethereum",
  mantle: "Mantle",
  mode: "Mode",
  opbnb: "opBNB",
  optimism: "Optimism",
  polygon: "Polygon",
  scroll: "Scroll"
};

// These correspond to the native gas token of the networks
export const nativeTokens: { [key: string]: string } = {
  arbitrum: "ETH",
  avalanche: "AVAX",
  celo: "CELO",
  base: "ETH",
  blast: "ETH",
  bsc: "BNB",
  ethereum: "ETH",
  mantle: "MNT",
  mode: "ETH",
  opbnb: "BNB",
  optimism: "ETH",
  polygon: "MATIC",
  scroll: "ETH"
}

export const getChainName = (key: string): string => chainNames[key] || "Unknown Chain";

export const getGasToken = (key: string): string => nativeTokens[key] || "Unknown";