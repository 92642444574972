import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import hourglassLogo from 'src/assets/images/HG-Logo-White-Font-p-500.png'
import machLogo from 'src/assets/images/machLogo.svg'
import ConnectButton from 'src/components/buttons/ConnectButton'
import { MainMenu } from 'src/configs/menu'
import * as PATH from 'src/configs/paths'
import { useClickOutside } from 'src/hooks/useClickOutside'

declare global {
	interface Window {
		ethereum?: any
	}
}

const Header = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
	const ref = useClickOutside(() => setIsMenuOpened(false))

	const gotoPages = (path: string) => {
		navigate(path)
	}

	const logoImage = window.location.href.includes('hourglass') ? (
		<img className='w-32 cursor-pointer md:w-36' src={hourglassLogo} onClick={() => gotoPages(PATH.LANDING)} />
	) : (
		<img className='h-14 cursor-pointer md:h-16' src={machLogo} onClick={() => gotoPages(PATH.LANDING)} />
	)

	return (
		<header className='container flex items-center justify-between py-6 md:py-4' ref={ref}>
			<div className='flex flex-1 justify-start'>{logoImage}</div>
			<div className='flex flex-1 justify-center'>
				<div className='hidden text-sm font-semibold md:flex md:items-center md:gap-10'>
					{MainMenu.map((menu: IMenuItem, index: number) => (
						<Link
							className={cn('hover:text-opacity-50', menu.navigation === pathname ? 'text-white' : 'text-sub-text')}
							key={index}
							to={menu.navigation}
						>
							{menu.label}
						</Link>
					))}
				</div>
			</div>
			<div className='flex flex-1 justify-end'>
				{window.location.pathname !== PATH.ABOUT && (
					<div className='left-container ml-0 hidden items-center justify-end pt-4 md:order-2 md:ml-5 md:flex md:pt-0'>
						<ConnectButton />
					</div>
				)}
				<div
					className='relative h-6 w-5 cursor-pointer transition-all hover:opacity-70 md:hidden'
					onClick={() => setIsMenuOpened((prev) => !prev)}
				>
					<span
						className={cn(
							'absolute top-0 block h-1 w-6 bg-[#9e9e9e] transition-all',
							isMenuOpened ? 'origin-top-left rotate-45' : ''
						)}
					></span>
					<span
						className={cn('absolute top-2 block h-1 w-6 bg-[#9e9e9e] transition-all', isMenuOpened ? 'opacity-0' : '')}
					></span>
					<span
						className={cn(
							'absolute top-4 block h-1 w-6 bg-[#9e9e9e] transition-all',
							isMenuOpened ? 'origin-bottom-left -rotate-45' : ''
						)}
					></span>
				</div>
			</div>

			{isMenuOpened && (
				<div className='fixed left-0 right-0 top-24 z-50 flex flex-col rounded-b-2xl border-b border-b-white/30 bg-background p-4'>
					{MainMenu.map((menu: IMenuItem, index: number) => (
						<Link
							className={cn(
								'py-5 text-center transition-all hover:text-white',
								menu.navigation === pathname ? 'text-white' : 'text-sub-text'
							)}
							key={index}
							to={menu.navigation}
							onClick={() => setIsMenuOpened(false)}
						>
							{menu.label}
						</Link>
					))}
					{window.location.pathname !== PATH.ABOUT && <ConnectButton />}
				</div>
			)}
		</header>
	)
}

export default Header
