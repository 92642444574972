import { useCallback } from 'react'
import cn from 'classnames'
import { debounce } from 'lodash'
import SearchIcon from '../icons/Search'

type SearchInputProps = {
	onValueChange?: React.Dispatch<string>
} & React.InputHTMLAttributes<HTMLInputElement>

const SearchTokenInput: React.FC<SearchInputProps> = ({ onValueChange, className, ...props }: SearchInputProps) => {
	const debounceChangeHandler = useCallback(
		debounce((e: React.ChangeEvent<HTMLInputElement>) => onValueChange && onValueChange(e.target?.value), 300),
		[onValueChange, debounce]
	)

	return (
		<div className={cn('flex items-center space-x-2 rounded-lg bg-component-background px-3 py-2', className)}>
			<SearchIcon />
			<input
				{...props}
				className='flex-grow bg-transparent text-sm outline-none'
				placeholder='Search token'
				onChange={debounceChangeHandler}
			/>
		</div>
	)
}

export default SearchTokenInput
