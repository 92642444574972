import { useMemo } from 'react'
import { useAccount } from 'wagmi'
import { useAppSelector } from './redux'

export const useSupportedChain = () => {
	const { chainId } = useAccount()
	const { availableNetworks } = useAppSelector((state) => state.configSlice)

	const isValidChain = useMemo(() => {
		if (!chainId) return false
		return Object.keys(availableNetworks).findIndex((nId) => Number(nId) === +chainId) >= 0
	}, [chainId, availableNetworks])

	return { isValidChain }
}
