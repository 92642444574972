import cn from 'classnames'

const Button = ({
	className,
	children,
	...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
	return (
		<button
			className={cn(
				'flex items-center justify-center space-x-[10px] rounded-2xl bg-primary px-[18px] py-[14px] font-medium leading-7 disabled:bg-opacity-25',
				className
			)}
			{...props}
		>
			{children}
		</button>
	)
}

export default Button
