import { useBalance } from 'wagmi'

export const useTokenBalance = (chainId: number, tokenAddress: string | undefined, address: string) => {
	const { data: balance, isLoading: isBalanceLoading } = useBalance({
		address: address as `0x${string}`,
		chainId: +chainId,
		token: tokenAddress as `0x${string}`,
		query: { refetchInterval: 30000 },
	})

	return { balance, isBalanceLoading }
}
