import React from 'react'
import cn from 'classnames'
import hourglassLogo from 'src/assets/images/HG-Logo-White-Font-p-500.png'
import machLogo from 'src/assets/images/machLogo.svg'

interface CardProps extends React.PropsWithChildren {
	style?: React.CSSProperties
	className?: string
}

const Card: React.FC<CardProps> = (props) => {
	const { children, style, className } = props

	//if url has hourglass in it, use hourglass logo, else use mach logo
	const logo = window.location.href.includes('hourglass') ? hourglassLogo : machLogo

	return (
		<div className={'flex w-[421px] max-w-full justify-center rounded-[20px] border border-white'} style={style}>
			<div className={cn('card-container flex w-[28rem] flex-col justify-between p-6', className)}>{children}</div>
		</div>
	)
}

export default Card
