import { useEffect, useRef, useState } from 'react'
import { type GetTransactionReceiptReturnType, getTransactionReceipt } from '@wagmi/core'
import { config } from 'src/providers/Web3ModalProvider'

const MAXIMUM_RETRY_COUNT = 15

export const useWaitForTransaction = () => {
	const [retryCount, setRetryCount] = useState<number>(0)
	const [transactionHash, setTransactionHash] = useState<string>('')
	const [transactionStatus, setTransactionStatus] = useState({ isSuccess: false, isError: false })

	const transactionStatusRef = useRef<any>(null)

	transactionStatusRef.current = transactionStatus

	const waitForTransactionReceipt = (hash: string): Promise<GetTransactionReceiptReturnType> => {
		return new Promise((resolve, reject) => {
			setTransactionHash(hash)

			const interval = setInterval(async () => {
				if (retryCount >= MAXIMUM_RETRY_COUNT) {
					setTransactionStatus({ isSuccess: false, isError: true })
					clearInterval(interval)
					reject({ message: 'Exceeded maximum retry count' })
				}

				try {
					const receipt = await getTransactionReceipt(config, { hash: hash as `0x${string}` })
					setTransactionStatus({ isError: false, isSuccess: true })
					clearInterval(interval)
					resolve(receipt) // return the full receipt here
				} catch (error) {
					setRetryCount((prev) => prev + 1)
				}

				setRetryCount((prev) => prev + 1)
			}, 1000)
		})
	}
	useEffect(() => {
		setRetryCount(0)
	}, [transactionHash])

	return { waitForTransactionReceipt }
}
