import { SVGProps } from 'react'

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g id='Chevron'>
				<path
					id='arrow / chevron_down'
					d='M5.98926 9.7023L11.9997 15.7127L18.0101 9.7023L16.5959 8.28809L11.9997 12.8843L7.40347 8.28809L5.98926 9.7023Z'
					fill='white'
				/>
			</g>
		</svg>
	)
}

export default ChevronDownIcon
