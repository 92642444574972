import { SVGProps } from 'react'

const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width='25' height='24' viewBox='0 0 25 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
			<g id='icon/close-small'>
				<path
					id='Vector'
					d='M7.5 7L17.5 17'
					stroke='currentColor'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M7.5 17L17.5 7'
					stroke='currentColor'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	)
}

export default CloseIcon
