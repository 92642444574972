import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetNativeTokenPrices } from 'src/api/price.api'
import { coinmarketcapApiDataAbbreviation } from 'src/configs/coingecko'

type InitialStateProps = {
	nativeTokenPrices: Record<string, any>
}

const initialState: InitialStateProps = {
	nativeTokenPrices: {},
}

export const getNativeTokenPrices = createAsyncThunk(
	'price/getNativeTokenPrices',
	async (networks: Record<string, any>) => {
		const data = await apiGetNativeTokenPrices()
		const nativeTokenPrices: Record<string, any> = {}
		for (const chainId of Object.keys(networks)) {
			const abbreviation = coinmarketcapApiDataAbbreviation[networks[chainId].gasToken]
			if (abbreviation && data[abbreviation]) {
				nativeTokenPrices[chainId] = {
					symbol: networks[chainId].abbreviation,
					price: data[abbreviation].usd,
				}
			}
		}
		return nativeTokenPrices
	}
)

export const priceSlice = createSlice({
	name: 'price',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getNativeTokenPrices.fulfilled, (state, action) => {
			state.nativeTokenPrices = action.payload
		})
	},
})

export default priceSlice.reducer
