import { GetTransactionReceiptReturnType, decodeEventLog } from 'viem'

export const processReceipt = (
	receipt: GetTransactionReceiptReturnType,
	encodedEventTopics: `0x${string}` | '',
	sellChain: string,
	contractAbi: Record<string, any>[]
) => {
	const orderPlacedLogs = receipt.logs.filter((log: any) => log.topics[0] === encodedEventTopics)

	const decodedLogs = orderPlacedLogs.map((log) => {
		const { args }: { args: any } = decodeEventLog({
			abi: contractAbi,
			data: log.data,
			topics: log.topics,
		})

		return {
			srcToken: args['src_token'] || args?.direction?.srcAsset,
			dstToken: args['dst_token'] || args?.direction?.dstAsset,
			lzCid: args['lz_cid']?.toString() || args?.direction?.dstLzc?.toString(),
			orderIndex: args['order_index']?.toString() || args['orderIndex']?.toString(),
			amount: args['amount']?.toString(),
			price: args['price']?.toString(),
			fee: args['fee']?.toString() || args?.funding?.bondFee?.toString(),
			transactionHash: log.transactionHash,
			sellChain: sellChain.toLowerCase(),
		}
	})

	return decodedLogs
}
