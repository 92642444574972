import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import hourglassLogo from 'src/assets/images/HG-Logo-White-Font-p-500.png'
import machLogo from 'src/assets/images/machLogo.svg'
import Card from 'src/components/Card'
import * as PATH from 'src/configs/paths'

type ILeaderboard = {
	wallet: string
	points: number
	rank: number
}
type Props = {
	account?: string | null
	loading: boolean
	leaderboard: ILeaderboard[]
}

export const Leaderboard: React.FC<Props> = ({ account, loading, leaderboard }) => {
	const navigate = useNavigate()
	//if url has hourglass in it, use hourglass logo, else use mach logo
	const logo = window.location.href.includes('hourglass') ? hourglassLogo : machLogo

	return (
		<Card style={{ minHeight: 586 }}>
			<div className='flex h-full flex-col justify-between'>
				<div>
					<div className='relative'>
						<img src={logo} className='absolute h-8' />
						<h2 className='pb-4 text-center text-lg font-bold'>Leaderboard</h2>
					</div>
					{loading ? (
						<img src='/gifs/sandwatch-loading.gif' alt='status' className='m-auto h-40' />
					) : (
						<div className='flex flex-col items-center rounded-lg bg-black/25 p-6'>
							<table className='w-full'>
								<thead>
									<tr>
										<th>Rank</th>
										<th>Wallet</th>
										<th>Miles</th>
									</tr>
								</thead>
								<tbody>
									{leaderboard.map((board) => (
										<tr
											className={cn('text-center', board.wallet === account ? 'text-purple-600' : 'text-white')}
											key={board.wallet}
										>
											<td>{board.rank}</td>
											<td>
												{board.wallet.substring(0, 7)}...
												{board.wallet.substring(board.wallet.length - 5)}
											</td>
											<td>{board.points}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
				{!loading && (
					<button
						className='gradient-button mt-4 w-full cursor-pointer rounded-lg border-0 bg-swap-new py-3 text-base font-semibold text-white'
						onClick={() => navigate(PATH.LANDING)}
					>
						Launch App
					</button>
				)}
			</div>
		</Card>
	)
}
