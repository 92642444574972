import React, { useState } from 'react'
import cn from 'classnames'
import { useAppSelector } from 'src/hooks/redux'
import { useAvailableTokens } from 'src/hooks/useAvailableTokens'
import ChainToken from '../ChainToken'
import ChevronDownIcon from '../icons/ChevronDown'
import TokenSelectModal from '../modals/TokenSelectModal'

interface Props {
	title?: string
	onChange: (__newData: IExchangeData) => void
	value: IExchangeData
	disabledChains?: number[]
	disabledTokens?: IToken[]
}

const TokenSelectionPill: React.FC<Props> = ({ onChange, value, title, disabledChains, disabledTokens }) => {
	const { networkId, token } = value
	const { availableNetworks } = useAppSelector((state) => state.configSlice)
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const { tokens } = useAvailableTokens(networkId)

	const onSelectToken = () => {
		setIsOpen((prev) => !prev)
	}

	return (
		<div className='relative'>
			<div
				className={cn(
					'flex cursor-pointer items-center rounded-[18px] border bg-component-background px-2 py-[6px] sm:px-3',
					isOpen ? 'border-modal-border' : 'border-transparent'
				)}
				onClick={() => onSelectToken()}
			>
				<div className='flex w-[100px] items-center py-[3px] sm:w-[120px]'>
					<ChainToken
						tokenLogo={token?.logoURI}
						networkIcon={availableNetworks[networkId]?.icon}
						className='mr-[6px] shrink'
					/>
					<p className='ml-2 text-sm font-medium sm:text-base'>{token?.symbol}</p>
				</div>
				<ChevronDownIcon className={cn('h-6 w-6 transition-all', isOpen ? 'rotate-180' : '')} />
			</div>

			<TokenSelectModal
				title={title}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				selectedNetwork={networkId}
				setSelectedNetwork={(__networkId) => onChange({ networkId: +__networkId })}
				selectedToken={token}
				setSelectedToken={(__token) => {
					setIsOpen(false)
					onChange({ token: __token, networkId })
				}}
				networks={availableNetworks}
				tokens={tokens}
				disabledChains={disabledChains}
				disabledTokens={disabledTokens}
			/>
		</div>
	)
}

export default TokenSelectionPill
