import { readContract } from '@wagmi/core'
import { config } from 'src/providers/Web3ModalProvider'
import { erc20Abi, formatUnits } from 'viem'

export const getAllowance = async (
	chainId: number,
	address?: string,
	owner?: string,
	spender?: string,
	decimal?: number
) => {
	const result = await readContract(config, {
		abi: erc20Abi,
		address: address as `0x${string}`,
		chainId,
		functionName: 'allowance',
		args: [owner as `0x${string}`, spender as `0x${string}`],
	})

	if (!decimal || !result) return 0

	return +formatUnits(result, decimal)
}
