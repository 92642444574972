import { SVGProps } from 'react'

const SortIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width='40' height='40' viewBox='0 0 40 40' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
			<g id='sort 1'>
				<path
					id='Vector'
					d='M14.3998 25.7992L8.7998 25.7992L17.1998 34.1992L17.1998 6.19922L14.3998 6.19922L14.3998 25.7992ZM22.7998 10.3992L22.7998 34.1992L25.5998 34.1992L25.5998 14.5992L31.1998 14.5992L22.7998 6.19922L22.7998 10.3992Z'
					fill='currentColor'
				/>
			</g>
		</svg>
	)
}

export default SortIcon
