export enum ConfigLoadingStatus {
	loading = 'loading',
	loaded = 'loaded',
	error = 'error',
}

export enum ExchangeType {
	single = 'hg_singlechain',
	multi = 'order_book',
	cctp = 'cctp_token_messenger',
}

export enum ResultCode {
	success = 'Success',
	failure = 'Failure',
    pending = 'Pending'
}

export enum StepNames {
	UNSTARTED = 'UNSTARTED',
	SWITCH_NETWORKS = 'SWITCH_NETWORKS',
	APPROVE_TOKENS = 'APPROVE_TOKENS',
	DEPOSIT_WETH = 'DEPOSIT_WETH',
	CONFIRM_SWAP = 'CONFIRM_SWAP',
	MATCHING = 'MATCHING',
}
