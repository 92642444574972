import { SVGProps } from 'react'

const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<g id='search (1) 1'>
				<g id='layer1'>
					<path
						id='circle2017'
						d='M13.8585 14.8964L13.7951 14.8331L13.7253 14.8891C12.4592 15.9026 10.8578 16.5127 9.11654 16.5127C5.03961 16.5127 1.71816 13.1849 1.71816 9.10776C1.71816 5.0307 5.0395 1.70938 9.11654 1.70938C13.1936 1.70938 16.5165 5.03073 16.5165 9.10776C16.5165 10.851 15.9057 12.455 14.8913 13.7231L14.8354 13.793L14.8987 13.8563L18.0205 16.978C18.0207 16.9783 18.021 16.9785 18.0212 16.9788C18.1536 17.1177 18.2264 17.3027 18.2242 17.4946C18.222 17.6867 18.1445 17.8704 18.0085 18.0062C17.8726 18.142 17.6888 18.2191 17.4966 18.2211C17.3047 18.2231 17.1197 18.1499 16.9811 18.0174C16.9808 18.0171 16.9806 18.0169 16.9803 18.0167L13.8585 14.8964ZM15.0482 9.10776C15.0482 5.821 12.4033 3.17607 9.11654 3.17607C5.82978 3.17607 3.18483 5.821 3.18483 9.10776C3.18483 12.3944 5.82963 15.046 9.11654 15.046C12.4034 15.046 15.0482 12.3944 15.0482 9.10776Z'
						fill='white'
						stroke='white'
						strokeWidth='0.2'
					/>
				</g>
			</g>
		</svg>
	)
}

export default SearchIcon
