import { SVGProps } from 'react'

const ArrowTopRightIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width='800px'
			height='800px'
			viewBox='0 0 24 24'
			role='img'
			xmlns='http://www.w3.org/2000/svg'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			fill='currentColor'
			color='currentColor'
			{...props}
		>
			<title id='arrowRightTopIconTitle'>Arrow Right Top</title> <path d='M19 13V5h-8' />
			<path strokeLinecap='round' d='M19 5l-1 1' /> <path d='M18 6L5 19' />
		</svg>
	)
}

export default ArrowTopRightIcon
