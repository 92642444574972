import { useEffect, useMemo, useState } from 'react'
import { getBalance } from '@wagmi/core'
import { apiGetBalances } from 'src/api/wallet.api'
import { config } from 'src/providers/Web3ModalProvider'
import { formatUnits } from 'src/utils/formatBalance'
import { useAccount } from 'wagmi'
import { useAppSelector } from './redux'

interface IBalance {
	symbol: string
	name: string
	balance: number
	networkId: string | number
	networkLogo: string
	tokenLogo: string
	price: number
}

const useBalances = () => {
	const { availableNetworks, availableTokens } = useAppSelector((state) => state.configSlice)
	const { nativeTokenPrices } = useAppSelector((state) => state.priceSlice)
	const { address, chainId } = useAccount()
	const [balances, setBalances] = useState<IBalance[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const totalBalance = useMemo(() => {
		return balances.reduce((res, cur) => res + cur.price * cur.balance, 0)
	}, [balances])

	useEffect(() => {
		const getBalances = async () => {
			setIsLoading(true)

			try {
				if (!address || !chainId || !Object.keys(availableNetworks).length) {
					setIsLoading(false)
					return
				}

				const balances = await apiGetBalances(address)
				const arr: IBalance[] = []
				const networks = [...Object.keys(availableNetworks).filter((network) => +network !== +chainId).map(chainId => +chainId)]
				for (const chainId of networks) {
					try {
						const { formatted: nativeTokenBalance, symbol } = await getBalance(config, {
							address: address,
							chainId: chainId,
						})
						if (+nativeTokenBalance !== 0)
							arr.push({
								symbol,
								name: symbol,
								balance: +nativeTokenBalance,
								networkId: chainId,
								networkLogo: availableNetworks[chainId]?.icon || (symbol === "ETH" ? "/assets/network/ethereum.png" : ""),
								tokenLogo: symbol === "ETH" ? "/assets/network/ethereum.png" : availableNetworks[chainId]?.icon,
								price: +nativeTokenPrices[chainId]?.price || 0,
							})
					} catch (err) {
						console.log('Error while getting native token balance', err)
					}

					const networkName = availableNetworks[chainId]?.name?.toLowerCase()

					const networkTokens = availableTokens.filter((token) => +token.chainId === +chainId)
					
					// If user is connected to an unkown network it may cause bugs
					if (!(networkName in balances)) continue

					for (const [name, value] of Object.entries(balances[networkName])) {
						const token = networkTokens.find((token) => +token.chainId === +chainId && token.symbol === name)
						const tokenBalance = formatUnits(BigInt(+value || 0), token?.decimals || 6)
						if (+tokenBalance > 0) {
							arr.push({
								symbol: token?.symbol || '',
								name: token?.name || '',
								balance: +tokenBalance,
								networkId: chainId,
								networkLogo: availableNetworks[chainId]?.icon,
								tokenLogo: token?.logoURI || '',
								price: 1,
							})
						}
					}
				}
				// Sort balances by balance times price
				arr.sort((a, b) => b.balance * b.price - a.balance * a.price)

				setBalances(arr)
			} catch (err) {
				console.log('Error while getting ERC20 token balance', err)
				setBalances([])
			} finally {
				// Set loading to false after the balance fetching succeeds or fails
				setIsLoading(false)
			}
		}

		// Check if nativeTokenPrices is fetched
		if (Object.keys(nativeTokenPrices).length > 0) {
			getBalances()
		} else {
			setIsLoading(true)
		}
	}, [availableNetworks, availableTokens, address, nativeTokenPrices])

	return { balances, isLoading, totalBalance }
}
export default useBalances
