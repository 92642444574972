/* eslint-disable @typescript-eslint/no-unsafe-return */
import { cacheHalfFullApi } from 'src/libs/axios'

export const apiGetConfig = async () => {
	try {
		const { data } = await cacheHalfFullApi.get('/get_config')
		return data
	} catch (err) {
		throw new Error((err as Error).message)
	}
}
