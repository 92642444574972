/* eslint-disable @typescript-eslint/no-unsafe-return */
import { cacheHalfFullApi } from 'src/libs/axios'

export const apiGetCheckPoints = async (address: string) => {
	try {
		const { data } = await cacheHalfFullApi.get(`/v1/points/${address}`)
		return data
	} catch (err) {
		throw new Error((err as Error).message)
	}
}

export const apiGetLeaderboard = async () => {
	try {
		const { data } = await cacheHalfFullApi.get(`/v1/points`)
		return data
	} catch (err) {
		throw new Error((err as Error).message)
	}
}
