import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type errorType = {
	message: string
}

type initialStateType = {
	sign: string
	tokens: ICoin[]
	error: errorType
}

const initialState: initialStateType = {
	sign: '',
	tokens: [] as ICoin[],
	error: { message: '' },
}

const landingSlice = createSlice({
	name: 'Landing',
	initialState,
	reducers: {
		setConnectedSign(state: initialStateType, action: PayloadAction<{ sign: string }>) {
			state.sign = action.payload.sign
		},
	},
})

export const { setConnectedSign } = landingSlice.actions

export default landingSlice.reducer
