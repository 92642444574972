import { useSendTransaction } from 'wagmi'
import { useWaitForTransaction } from './useWaitForTransaction'

export const useDepositWeth = () => {
	const { data, sendTransaction, error: sendError } = useSendTransaction()
	const { waitForTransactionReceipt } = useWaitForTransaction()

	const depositWeth = async (address?: string, amount?: bigint) => {
		if (!address || !amount) {
			console.error('Address and amount are required')
			return
		}

		try {
			await sendTransaction({
				to: address as `0x${string}`,
				value: amount,
				data: '0xd0e30db0', // Function selector for "deposit()"
			})

			// Check if data (transaction hash) is available
			if (data) {
				try {
					await waitForTransactionReceipt(data)
					console.log('Deposit successful')
				} catch (receiptError) {
					console.error('Error waiting for transaction receipt:', receiptError)
					throw receiptError
				}
			} else {
				console.error('Transaction hash not available')
			}
		} catch (error) {
			console.error('Error depositing WETH:', error)
			throw error
		}
	}

	return { 
		depositWeth, 
		isLoading: !sendTransaction,
		error: sendError
	}
}