import { useEffect, useMemo, useState } from 'react'
import { formatEther } from 'viem'
import { useGasPrice } from 'wagmi'
import { apiGetEstimateGasPrice } from 'src/api/gas.api'
import { useAppSelector } from './redux'

export const useEstimateGasPrice = (sellData: IExchangeData, buyData: IExchangeData) => {
	const { availableNetworks } = useAppSelector((state) => state.configSlice)
	const [estimatedGasPrice, setEstimatedGasPrice] = useState<number>(0)
	const { nativeTokenPrices } = useAppSelector((state) => state.priceSlice)
    const { data: gasPrice } = useGasPrice()

	const price: number = useMemo(() => {
		return (nativeTokenPrices[sellData.networkId]?.price || 0) as number
	}, [sellData.networkId, nativeTokenPrices])

	useEffect(() => {
		(async () => {
			if (
				!availableNetworks ||
				!buyData.token?.zeroChainID ||
				!availableNetworks[sellData.networkId] ||
				!sellData ||
				!buyData ||
				!price
			) {
				return
			}

			try {
				const { gas_estimate: gasEstimate }: { gas_estimate: number } = await apiGetEstimateGasPrice(
					availableNetworks[sellData.networkId]?.name,
					buyData.token?.zeroChainID
				)

				setEstimatedGasPrice(price * gasEstimate * +formatEther(BigInt(gasPrice || 0), 'wei'))
			} catch (err) {
				setEstimatedGasPrice(0)
			}
		})()
	}, [sellData, buyData, availableNetworks, price])

	return { estimatedGasPrice }
}
