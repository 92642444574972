// import models
import * as PATH from './paths'

const MainMenu: IMenuItem[] = [
	{
		label: 'Swap',
		active: 'active',
		inactive: 'inactive',
		navigation: PATH.LANDING,
	},
	{
		label: 'Orders',
		active: 'active',
		inactive: 'inactive',
		navigation: PATH.ORDERS,
	},
	// {
	// 	label: 'POINTS',
	// 	active: 'active',
	// 	inactive: 'inactive',
	// 	navigation: PATH.POINTS,
	// },
	{
		label: 'Docs',
		active: 'active',
		inactive: 'inactive',
		navigation: PATH.DOCS,
	},
	{
		label: 'Miles',
		active: 'active',
		inactive: 'inactive',
		navigation: PATH.POINTS,
	},
    {
        label: 'Developers',
        active: 'active',
        inactive: 'inactive',
        navigation: PATH.DEVS
    }
]

export { MainMenu }
