import { Link } from 'react-router-dom'
import TelegramIcon from 'src/components/icons/Telegram'
import TwitterIcon from 'src/components/icons/Twitter'

const Footer = () => {
	const name = window.location.href.includes('hourglass') ? 'Hourglass' : 'Mach'
	return (
		<footer className='container flex flex-col items-center space-y-4 py-8 md:flex-row md:justify-between md:space-y-0'>
			<div className='flex space-x-8 text-sm font-semibold'>
				<Link to='https://drive.google.com/file/d/1zwcpFp-Mkjiz9f77Q5oWr8jKrlfwvVmL/view' target='_blank'>
					Audit
				</Link>
				<Link to='mailto:support@tristero.xyz'>Contact</Link>
				<Link
					to='https://docs.google.com/forms/d/1j2jOzuAibZA3-5g84HNYEyG8c8plgkJ1-Nf7_pAnC1Q/viewform?edit_requested=true'
					target='_blank'
				>
					Bug & Feedback Form
				</Link>
			</div>
			<div className='flex items-center space-x-4'>
				<span className='pr-1 text-sm font-semibold'>Copyright © 2024 {name} Protocol</span>
				<Link
					className='flex h-8 w-8 items-center justify-center rounded-full bg-primary p-[6px]'
					to='https://twitter.com/intent/follow?screen_name=machfinance&original_referer=https://developer.twitter.com/en/docs/twitter-for-websites/web-intents/overview'
					target='_blank'
				>
					<TwitterIcon />
				</Link>
				<Link
					className='flex h-8 w-8 items-center justify-center rounded-full bg-primary p-[6px]'
					to='https://t.me/+7YaDMvg4-sM1YmQx'
					target='_blank'
				>
					<TelegramIcon />
				</Link>
			</div>
		</footer>
	)
}

export default Footer
