/* eslint-disable @typescript-eslint/no-unsafe-return */
import { cacheHalfFullApi } from 'src/libs/axios'

export const apiGetBalances = async (address: string): Promise<Record<string, Object>> => {
	try {
		const { data } = await cacheHalfFullApi.get(`/tokenBalances?wallet_address=${address}`)

		return data?.balances || {}
	} catch (err) {
		console.log('Get Balance Error', err)
		return {}
	}
}
