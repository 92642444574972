import { useCallback, useMemo, useState } from 'react'
import { apiGetQuote, apiNewPostReceiveCctpMessage, apiSendOrderData } from 'src/api/order.api'
import { ExchangeType, ResultCode, StepNames } from 'src/enums'
import { setCurrentModalId } from 'src/redux/slices/application.slice'
import { DEFAULT_STEPS, getOrder, removeHistory, updateHistory } from 'src/redux/slices/history.slice'
import { getAllowance } from 'src/utils/getAllowance'
import { getBlockExplorerUrlFromId } from 'src/utils/getBlockExplorerUrl'
import { processReceipt } from 'src/utils/processReceipt'
import { encodeEventTopics, pad } from 'viem'
import { useAccount, useSwitchChain, useWriteContract } from 'wagmi'
import { useAppDispatch, useAppSelector } from './redux'
import { useApproveToken } from './useApproveToken'
import { useEstimateGasPrice } from './useEstimateGasPrice'
import { useExchangeType } from './useExchangeType'
import { useOrderUpdates } from './useOrderUpdates'
import { useSwapContract } from './useSwapContract'
import { useWaitForTransaction } from './useWaitForTransaction'
import { useDepositWeth } from './useDepositWeth'
import BigNumber from 'bignumber.js'

// Assuming you have BigNumber library installed

const maxUint256 = BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')

export function parseUnits(value: string, decimals: number): bigint {
	let [integer, fraction = '0'] = value.split('.')

	const negative = integer.startsWith('-')
	if (negative) integer = integer.slice(1)

	// Ensure the fraction part has enough digits
	if (fraction.length > decimals) {
		fraction = fraction.slice(0, decimals) // Truncate to the specified decimals
	} else {
		fraction = fraction.padEnd(decimals, '0') // Pad with zeros to match the specified decimals
	}

	return BigInt(`${negative ? '-' : ''}${integer}${fraction}`)
}

export const useExchangeToken = (exchangeId: string) => {
	const dispatch = useAppDispatch()
	const { availableNetworks } = useAppSelector((state) => state.configSlice)
	const { sellData, buyData } = useAppSelector((state) => getOrder(state, exchangeId))

	const { address, chainId } = useAccount()
	const { switchChainAsync } = useSwitchChain()
	const { approveToken } = useApproveToken()
	const { depositWeth } = useDepositWeth()
	const { writeContractAsync } = useWriteContract()
	const { waitForTransactionReceipt } = useWaitForTransaction()

	const swapContract = useSwapContract(sellData, buyData)
	const { estimatedGasPrice } = useEstimateGasPrice(sellData, buyData)
	const exchangeType = useExchangeType(sellData, buyData)

	const [orderId, setOrderId] = useState<string>('')
	useOrderUpdates(address || '', orderId, exchangeId)

	const encodeTransactionArgs = async (
		wallet: string,
		sellData: IExchangeData,
		buyData: IExchangeData
	): Promise<any[]> => {
		if (!sellData.token || !buyData.token || !sellData.value || !buyData.value) {
			throw new Error('Invalid sell or buy token data')
		}

		if (!availableNetworks[sellData.networkId] || !availableNetworks[buyData.networkId]) {
			throw new Error('Invalid network for sell chain or buy chain')
		}

        const sellValue = new BigNumber(sellData.value)
        const safeSrcAmount = sellValue.multipliedBy(new BigNumber(10).pow(sellData.token?.decimals || 0))
		const sourceChainName = availableNetworks[sellData.networkId].name
		const destChainName = availableNetworks[buyData.networkId].name
		const quoteData = await apiGetQuote(
			wallet,
			sourceChainName,
			destChainName,
			sellData.token.address,
			buyData.token.address,
			safeSrcAmount.toString()
		)

		const orderDirection: OrderDirectionParameter = {
			srcAsset: sellData.token.address,
			dstAsset: buyData.token.address,
			dstLzc: buyData.token.zeroChainID || 0,
		}

		const orderFunding: OrderFundingParameter = {
			srcQuantity: BigInt(quoteData.src_amount),
			dstQuantity: BigInt(quoteData.dst_amount),
			bondFee: quoteData.bond_fee,
			bondAsset: quoteData.bond_asset_address,
			bondAmount: quoteData.bond_amount,
		}

		const orderExpiration: OrderExpirationParameter = {
			timestamp: Math.floor(new Date(quoteData.expires_at).getTime() / 1000),
			challengeOffset: quoteData.challenge_offset,
			challengeWindow: quoteData.challenge_window,
		}

		return [orderDirection, orderFunding, orderExpiration, false]
	}

	const encodedEventTopics = useMemo(() => {
		try {
			return encodeEventTopics({ abi: swapContract.abi, eventName: 'OrderPlaced' })[0]
		} catch (err) {
			return ''
		}
	}, [swapContract])

	const exchange = useCallback(
		async (referrer?: string) => {
			if (!sellData.value || !sellData.token || !buyData.token || !chainId || !address) {
				return
			}

			try {
				dispatch(
					updateHistory({
						exchangeId,
						currentStep: StepNames.SWITCH_NETWORKS,
						steps: DEFAULT_STEPS,
					})
				)

				if (+chainId !== +sellData.networkId) {
					await switchChainAsync({ chainId: +sellData.networkId })
				}

				dispatch(updateHistory({ exchangeId, currentStep: StepNames.APPROVE_TOKENS }))

				const allowance = await getAllowance(
					+sellData.networkId,
					sellData.token.address,
					address,
					swapContract.address,
					sellData.token.decimals
				)

				if (allowance < +sellData.value) {
					try {
						await approveToken(sellData.token.address, swapContract.address, maxUint256)
					} catch (approveError) {
						console.error('Error during token approval:', approveError)
						throw approveError
					}
				}

				if (sellData.token.wrapped) {
					dispatch(updateHistory({ exchangeId, currentStep: StepNames.DEPOSIT_WETH }))
					try {
						const depositAmount = parseUnits(sellData.value.toString(), sellData.token.decimals)
						await depositWeth(sellData.token.address, depositAmount)
					} catch (depositError) {
						console.error('Error during deposit WETH call:', depositError)
						throw depositError
					}
				}

				const transactionFunctionName = exchangeType === ExchangeType.cctp ? 'depositForBurn' : 'placeOrder'
				let transactionFunctionArgs

				dispatch(updateHistory({ exchangeId, currentStep: StepNames.CONFIRM_SWAP }))
				if (exchangeType === ExchangeType.multi) {
					const args = await encodeTransactionArgs(address, sellData, buyData)
					transactionFunctionArgs = args
				} else if (exchangeType === ExchangeType.cctp)
					transactionFunctionArgs = [
						parseUnits(sellData.value.toString(), sellData.token.decimals),
						availableNetworks[buyData.networkId].cctp_id,
						pad(address),
						sellData.token.address,
					]

				const hash = await writeContractAsync({
					abi: swapContract.abi,
					address: swapContract.address,
					functionName: transactionFunctionName,
					args: transactionFunctionArgs,
				})

				try {
					const receipt = await waitForTransactionReceipt(hash)
					const decodedData = processReceipt(
						receipt,
						encodedEventTopics,
						availableNetworks[sellData.networkId].name,
						swapContract.abi
					)

					const result: { status: ResultCode; message: string; id?: string } = await (exchangeType ===
					ExchangeType.multi
						? apiSendOrderData(decodedData[0], referrer)
						: apiNewPostReceiveCctpMessage(address, availableNetworks[sellData.networkId].name, hash))
					setOrderId(result.id || '')

					// Update order history if the result is a non success
					if (result.status !== 'Success') {
						const updateData = {
							exchangeId,
							currentStep: 3,
							result: {
								hash: `${getBlockExplorerUrlFromId(sellData.networkId)}/${hash}` as `0x${string}`,
								status: result.status,
								message: result.message,
								transactionETA: 0,
							},
						}
						dispatch(updateHistory(updateData))
					}
				} catch (receiptError) {
					console.error('Transaction receipt error:', receiptError)
					dispatch(updateHistory({ exchangeId, currentStep: StepNames.UNSTARTED, steps: [], result: { status: ResultCode.failure } }))
					throw receiptError
				}
			} catch (err) {
				console.error('Exchange error:', err)
				if ((err as any).shortMessage?.includes('User rejected the request')) {
					dispatch(setCurrentModalId(''))
					dispatch(removeHistory(exchangeId))
				} else {
					dispatch(updateHistory({ exchangeId, currentStep: StepNames.UNSTARTED, steps: [], result: { status: ResultCode.failure } }))
				}
				throw err
			}
		},
		[sellData, buyData, address, exchangeType, swapContract]
	)

	return {
		estimatedGasPrice,
		exchange,
	}
}
