//node_modules
import { combineReducers, configureStore } from '@reduxjs/toolkit'
//slices
import configSlice from './slices/config.slice'
import applicationSlice from './slices/application.slice'
import historySlice from './slices/history.slice'
import landingSlice from './slices/landing.slice'
import priceSlice from './slices/price.slice'

const reducer = combineReducers({
	landingSlice,
	configSlice,
	priceSlice,
	applicationSlice,
	historySlice,
})

export const store = configureStore({
	preloadedState: {},
	reducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
