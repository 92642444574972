import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { WagmiProvider } from 'wagmi'
import { supportedChains } from '../configs/supportedChains'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '0ee00ff68d93a2cfc969640942e42c31'

// 2. Create wagmiConfig
const metadata = {
	name: 'Mach',
	description: 'Mach Protocol liquidity layer for Web3.0',
	url: 'https://web3modal.com', // origin must match your domain & subdomain
	icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const config = defaultWagmiConfig({
	chains: supportedChains,
	projectId,
	metadata,
} as any)

// 3. Create modal
createWeb3Modal({
	wagmiConfig: config,
	projectId,
	enableAnalytics: true, // Optional - defaults to your Cloud configuration
	enableOnramp: true, // Optional - false as default
	themeVariables: {
		'--w3m-accent': '#9e58fb',
		'--w3m-z-index': 10000,
	},
})

export function Web3ModalProvider({ children }: React.PropsWithChildren) {
	return (
		<WagmiProvider config={config}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</WagmiProvider>
	)
}
