import { useEffect, useRef } from 'react'
import { Route, Routes } from 'react-router-dom'
import * as PATH from 'src/configs/paths'
import { useAppDispatch, useAppSelector } from './hooks/redux'
import Layout from './layouts/Layout'
import { ExchangePage, OrdersPage } from './pages'
import PointsPage from './pages/Points'
import { getConfig } from './redux/slices/config.slice'
import { getNativeTokenPrices } from './redux/slices/price.slice'

const AppRoutes = () => {
	const dispatch = useAppDispatch()
	const { availableNetworks } = useAppSelector((state) => state.configSlice)

	const networkRef = useRef<any>()

	networkRef.current = availableNetworks

	useEffect(() => {
		dispatch(getConfig())
	}, [])

	useEffect(() => {
		const getTokenPrice = () => {
			dispatch(getNativeTokenPrices(availableNetworks))
		}

		const intervalId = setInterval(() => {
			getTokenPrice()
		}, 120000)

		if (availableNetworks) {
			getTokenPrice()
		}

		return () => clearInterval(intervalId)
	}, [availableNetworks])

	return (
		<Layout>
			<Routes>
				<Route path={PATH.LANDING} element={<ExchangePage />}></Route>
				<Route path={PATH.ORDERS} element={<OrdersPage />}></Route>
				<Route path={PATH.POINTS} element={<PointsPage />}></Route>
			</Routes>
		</Layout>
	)
}

export default AppRoutes
