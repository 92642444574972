import axios, { type InternalAxiosRequestConfig } from 'axios'
import { BACKEND_API_URL, CACHE_HALF_FULL_URL } from 'src/configs/env'

export const api = axios.create({ baseURL: BACKEND_API_URL })

api.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
	return config
})

export const cacheHalfFullApi = axios.create({ baseURL: CACHE_HALF_FULL_URL })

cacheHalfFullApi.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
	return config
})
