import { useMemo } from 'react'
import { useAppSelector } from './redux'

export const useAvailableTokens = (chainId: string | number) => {
	const { availableTokens } = useAppSelector((state) => state.configSlice)

	const tokens = useMemo(() => {
		return availableTokens.filter((token) => +token.chainId === +chainId)
	}, [chainId, availableTokens])

	return { tokens }
}
