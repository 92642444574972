import { erc20Abi } from 'viem'
import { useWriteContract } from 'wagmi'
import { useWaitForTransaction } from './useWaitForTransaction'

export const useApproveToken = () => {
	const { writeContractAsync } = useWriteContract()
	const { waitForTransactionReceipt } = useWaitForTransaction()

	const approveToken = async (address?: string, spender?: string, amount?: bigint) => {
		if (!address || !spender || !amount) {
			return
		}

		try {
			const hash = await writeContractAsync({
				abi: erc20Abi,
				address: address as `0x${string}`,
				functionName: 'approve',
				args: [spender as `0x${string}`, amount],
			})

			try {
				await waitForTransactionReceipt(hash)
			} catch (receiptError) {
				console.error('Error waiting for transaction receipt:', receiptError)
				// Handle the receipt error as needed, e.g., by returning a specific error message or status
				throw receiptError
			}
		} catch (writeError) {
			console.error('Error approving token:', writeError)
			// Handle the write error as needed, e.g., by returning a specific error message or status
			throw writeError
		}
	}

	return { approveToken }
}
