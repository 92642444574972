import { useEffect, useMemo, useState } from 'react'
import { apiGetAssets } from 'src/api/price.api'
import { ExchangeType } from 'src/enums'

export const useFee = (sellData: IExchangeData, exchangeType: ExchangeType, chainName: string) => {
	const [fee, setFee] = useState<number>(0);

	const calculatedFee = useMemo(() => {
		if (sellData.value === '' || exchangeType === ExchangeType.cctp) {
			return 0;
		}

		const sellValue = sellData.value ?? 0;
		const newMachFee = +sellValue * 0.0001;
		return newMachFee;
	}, [sellData, exchangeType]);

	useEffect(() => {
		const fetchAssets = async () => {
			try {
				const assets = await apiGetAssets(chainName);
			} catch (e) {
				console.error(e)
			}
		};

		fetchAssets();
	}, []);

	return fee;
};
