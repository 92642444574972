import { useAppSelector } from 'src/hooks/redux'
import { getBlockExplorerUrlFromId } from 'src/utils/getBlockExplorerUrl'
import ChainToken from '../ChainToken'
import { OrderItemTableRow } from '.'

const OrderHistoryItemRow = ({ item }: { item: OrderItemTableRow }) => {
	const { availableNetworks } = useAppSelector((state) => state.configSlice)

	const shortenAddress = (address: string) => {
		return address.length > 10
			? address.substring(0, 7) + '...' + address.substring(address.length - 7, address.length)
			: address
	}

	return (
		<>
			<tr key={`order-table-row-${item.originChainTxHash}`} className='py-4 text-sm'>
				<td className='px-2 py-4'>
					<ChainToken
						networkIcon={availableNetworks[item.originChainId]?.icon}
						tokenLogo={item.sellToken.logoURI}
					/>
				</td>
				<td><img src='/images/arrow-right.png' alt='arrow' className='w-12' /></td>
				<td className='px-2'>
					<ChainToken
						networkIcon={availableNetworks[item.destChainId]?.icon}
						tokenLogo={item.buyToken.logoURI}
					/>
				</td>
				<td className='px-2'>{item.sellQuantity / (10 ** item.sellToken.decimals)}</td>
				<td className='px-2'>{item.buyQuantity / (10 ** item.buyToken.decimals)}</td>
				<td className='px-2'>
					{item.originChainTxHash && (
						<a
							target='_blank'
							href={`${getBlockExplorerUrlFromId(item.originChainId)}/${item.originChainTxHash}`}
							className='font-semibold text-highlight-text underline'
							rel='noreferrer'
						>
							<span>{shortenAddress(item.originChainTxHash)}</span>
						</a>
					)}
				</td>
				<td className='px-2'>
					{item.destChainTxHash && (
						<a
							target='_blank'
							href={`${getBlockExplorerUrlFromId(item.destChainId)}/${item.destChainTxHash}`}
							className='font-semibold text-highlight-text underline'
							rel='noreferrer'
						>
							<span>{shortenAddress(item.destChainTxHash)}</span>
						</a>
					)}
				</td>
			</tr>
		</>
	)
}

export default OrderHistoryItemRow
