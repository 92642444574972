import { formatNumber } from './formatNumber'

export function formatUnits(value: bigint, decimals: number): string {
	let display = value.toString()

	const negative = display.startsWith('-')
	if (negative) display = display.slice(1)

	// Ensure the display string has enough length to handle the decimals
	display = display.padStart(decimals + 1, '0')

	const [integer, fraction] = [display.slice(0, display.length - decimals), display.slice(display.length - decimals)]

	// Do not remove trailing zeros in the fraction part
	return `${negative ? '-' : ''}${integer || '0'}.${fraction}`
}

export const formatBalance = (balance: IBalance | undefined): number => {
	if (!balance || !balance.value) return 0.0
	const formattedValue = formatUnits(BigInt(balance.value), balance.decimals)
	return parseFloat(formattedValue)
}

export const formatBalanceString = (balance: IBalance | undefined): string => {
	if (!balance || !balance.value) return '0.0'
	const formattedValue = formatUnits(BigInt(balance.value), balance.decimals)
	return formatNumber(formattedValue)
}
