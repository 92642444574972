import { useMemo } from 'react'
import cn from 'classnames'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	insufficientBalance?: boolean
	isQuoteInvalid?: boolean
	sellData?: IExchangeData
}

const SwapButton = ({ insufficientBalance = false, isQuoteInvalid = false, sellData, className, ...props }: Props) => {
	const inputRequired = useMemo(() => {
		return +(sellData?.value ?? 0) === 0
	}, [sellData])

	let buttonClass = 'flex select-none items-center justify-center space-x-[10px] rounded-2xl px-[18px] py-[14px] font-medium leading-7 transition-all'
	let buttonText = 'Swap'
	let buttonDisabled = insufficientBalance || isQuoteInvalid || inputRequired

    if (insufficientBalance) {
		buttonText = `Insufficient ${sellData?.token?.symbol ?? ''} balance`
		buttonClass = cn(buttonClass, 'bg-component-background')
	} else if (inputRequired) {
		buttonText = 'Enter Amount'
		buttonClass = cn(buttonClass, 'bg-component-background')
	} else if (isQuoteInvalid) {
		buttonText = 'Unable to fill order amount at this time'
		buttonClass = cn(buttonClass, 'bg-component-background')
	} else {
		buttonClass = cn(buttonClass, 'bg-swap-new hover:brightness-110')
	}

	return (
		<button
        className={cn(
            buttonClass,
            className
        )}
			disabled={buttonDisabled}
			{...props}
		>
			{buttonText}
		</button>
	)
}

export default SwapButton
