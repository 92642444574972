import { SVGProps } from 'react'

const LoadingSpinner = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			stroke='#FC72FF'
			width='20'
			height='20'
			{...props}
		>
			<path
				d='M2,12 a10,10 0 0,1 10,-10 M12,22 a10,10 0 0,1 -10,-10 M22,12 a10,10 0 0,1 -10,10'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			></path>
		</svg>
	)
}

export default LoadingSpinner
