import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { apiGetOrderHistory } from 'src/api/order.api'
import OrderHistoryTableComponent, { OrderItemTableRow } from 'src/components/OrderHistoryTable'
import SearchTokenInput from 'src/components/SearchTokenInput'
import ConnectButton from 'src/components/buttons/ConnectButton'
import NoWalletIcon from 'src/components/icons/NoWallet'
import { useAppSelector } from 'src/hooks/redux'
import { useAccount } from 'wagmi'
import { RootState } from '../../redux/store'

const OrdersPage = () => {
	const { address, isConnected } = useAccount()
	const { availableNetworks, availableTokens } = useAppSelector((state) => state.configSlice)

	const [search, setSearch] = React.useState('')
	const { sign } = useSelector((state: RootState) => state.landingSlice)

	const [orderHistoryResponse, setOrderHistoryResponse] = React.useState<OrderItemResponse[]>([])
	const [orderHistoryTableData, setOrderHistoryTableData] = React.useState<OrderItemTableRow[]>([])
	const [isLoading, setIsLoading] = React.useState<boolean>(true)

	const getChainIdFromName = (name: string) => {
		return Object.keys(availableNetworks).find(
			(chainId: string) => availableNetworks[chainId].name.toLowerCase() === name
		)
	}

	const getTokenFromAddress = (address: string) => {
		return availableTokens.find((token: IToken) => token.address === address)
	}

	const convertToTableRows = (data: OrderItemResponse[]) => {
		const orderTableRows: OrderItemTableRow[] = []

		for (const orderItem of data) {
			const sellToken = getTokenFromAddress(orderItem.src_asset_address)
			const buyToken = getTokenFromAddress(orderItem.dst_asset_address)
			const originChainId = getChainIdFromName(orderItem.src_chain)
			const destChainId = getChainIdFromName(orderItem.dst_chain)

			if (!sellToken || !buyToken || !originChainId || !destChainId) {
				// Skip rows with incomplete data
				continue
			}

			orderTableRows.push({
				sellToken: sellToken,
				buyToken: buyToken,
				sellQuantity: orderItem.src_amount,
				buyQuantity: orderItem.dst_amount,
				originChainId: originChainId,
				destChainId: destChainId,
				originChainTxHash: orderItem.place_tx,
				destChainTxHash: orderItem.fill_tx,
			})
		}

		setOrderHistoryTableData(orderTableRows)
	}

	const getOrderHistory = async () => {
		setIsLoading(true)
		try {
			if (!address) {
				return
			}
			const data: OrderItemResponse[] = await apiGetOrderHistory(address)
			setOrderHistoryResponse(data || [])
			convertToTableRows(data)
		} catch (err) {
			console.log(err)
			setOrderHistoryResponse([])
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		setIsLoading(true)
		const signData = localStorage.getItem('sign')
		if (address && isConnected && signData) {
			setOrderHistoryResponse([])
			getOrderHistory()
		} else {
			setOrderHistoryResponse([])
			setIsLoading(false)
		}
	}, [address, isConnected, sign])

	const searchedOrderHistory = useMemo(() => {
		if (!search) {
			return orderHistoryTableData
		}
		const searchLowerCase = search.toLowerCase()
		return orderHistoryTableData.filter(
			(order) =>
				availableNetworks[order.originChainId]?.name?.toLowerCase()?.includes(searchLowerCase) ||
				availableNetworks[order.destChainId]?.name?.toLowerCase()?.includes(searchLowerCase) ||
				order.originChainTxHash.toLowerCase().includes(search) ||
				order.destChainTxHash.toLowerCase().includes(search) ||
				order.buyToken.name.toLowerCase().includes(searchLowerCase) ||
				order.sellToken.name.toLowerCase().includes(searchLowerCase)
		)
	}, [search, orderHistoryResponse, orderHistoryTableData])

	return (
		<>
			<div className='container flex flex-col justify-between py-7 md:flex-row'>
				<h2 className='text-[32px] font-bold'>Orders</h2>
				<SearchTokenInput className='w-[400px] max-w-full' onValueChange={(val) => setSearch(val)} />
			</div>

			{!isConnected ? (
				<div className='container flex flex-grow flex-col items-center justify-center'>
					<div className='flex flex-col items-center justify-center space-y-[21px]'>
						<NoWalletIcon />
						<div className='space-y-3 text-center'>
							<p className='text-xl font-semibold'>No wallet connected</p>
							<span className='text-xs font-medium'>Connect a wallet to see records</span>
						</div>
						<ConnectButton />
					</div>
				</div>
			) : (
				<div className='container flex flex-grow flex-col items-center'>
					<OrderHistoryTableComponent data={searchedOrderHistory} loadingStatus={isLoading} search={search} />
				</div>
			)}
		</>
	)
}

export default OrdersPage
