import { SVGProps } from 'react'

const NoWalletIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width='131' height='118' viewBox='0 0 131 118' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M119.894 27.7221C121.788 26.081 121.914 23.1776 120.147 21.4103C118.505 19.643 115.602 19.5167 113.835 21.1578C111.941 22.7989 111.815 25.7023 113.582 27.4696C115.223 29.2369 118.127 29.3631 119.894 27.7221Z'
				fill='#2A2E37'
			/>
			<path
				d='M126.711 36.5673C127.468 35.8099 127.594 34.6738 126.837 33.9164C126.079 33.1589 124.943 33.0327 124.186 33.7901C123.428 34.5475 123.302 35.6837 124.06 36.4411C124.691 37.3247 125.953 37.3247 126.711 36.5673Z'
				fill='#2A2E37'
			/>
			<path
				d='M11.7643 111.45C12.5217 110.693 12.648 109.557 11.8905 108.799C11.1331 108.042 9.99701 107.916 9.2396 108.673C8.48218 109.43 8.35595 110.566 9.11336 111.324C9.87078 112.081 11.0069 112.208 11.7643 111.45Z'
				fill='#2A2E37'
			/>
			<path
				d='M59.4812 117.636C91.9238 117.636 118.307 91.3793 118.307 59.0629C118.181 26.6203 91.9238 0.363281 59.4812 0.363281C26.9123 0.363281 0.655273 26.6203 0.655273 58.9367C0.655273 91.3793 26.9123 117.636 59.4812 117.636Z'
				fill='#2A2E37'
			/>
			<path
				d='M98.5206 28.7446V33.2811H98.1716H93.7514L25.9371 33.0484C24.6576 33.0484 23.3781 32.8158 22.0985 32.4668C21.9822 32.4668 21.8659 32.3505 21.7496 32.3505C20.1211 31.7689 18.7253 31.071 17.5621 29.9078C16.6315 28.9772 15.9336 27.5814 15.9336 26.3019C15.9336 23.8592 17.911 21.9981 20.7027 20.8349C21.6333 20.3696 22.7965 20.1369 23.9597 20.0206C24.4249 20.0206 24.8902 19.9043 25.3555 19.9043H89.6803C90.3782 19.9043 90.9598 20.0206 91.5414 20.1369C92.0067 20.2533 92.4719 20.3696 92.9372 20.6022C96.3105 21.9981 98.5206 25.0224 98.5206 28.7446Z'
				fill='#6C758B'
			/>
			<path
				opacity='0.1'
				d='M98.172 33.2813H93.7519L25.9375 33.0486C24.658 33.0486 23.3785 32.816 22.099 32.467C21.9826 32.467 21.8663 32.3507 21.75 32.3507C20.1215 31.7691 18.7257 31.0712 17.5625 29.908C18.3767 29.0938 19.4236 28.3958 20.7031 27.9306C21.6337 27.4653 22.7969 27.2326 23.9601 27.1163C24.4254 27 24.8906 27 25.3559 27H89.6807C90.3786 27 90.9602 27.1163 91.5418 27.2326C92.0071 27.349 92.4724 27.4653 92.9376 27.6979C95.4967 28.7448 97.3578 30.8386 98.172 33.2813Z'
				fill='#020305'
			/>
			<path
				d='M106.779 40.3772V97.4901C106.779 101.445 103.639 104.586 99.6839 104.586H31.1716C25.4719 104.586 23.5 105.319 19.5 103C16.05 101 16.1663 97.0249 16.1663 95.3964C16.1663 94.5822 16.1663 93.7679 16.1663 92.9537C16.1663 91.7905 16.1663 90.3946 16.1663 88.9988C16.1663 82.4849 16.1663 73.7609 16.05 64.9206C16.05 63.6411 16.05 62.3616 16.05 60.9658C16.05 56.313 16.05 51.6602 15.9337 47.2401C15.9337 45.9605 15.9337 44.681 15.9337 43.2852C15.9337 34.5612 15.8174 28.0473 15.8174 26.5352C15.8174 29.0942 18.2601 31.4206 21.517 32.3511C21.6334 32.3511 21.7497 32.4675 21.866 32.4675C23.0292 32.8164 24.425 33.0491 25.7046 33.0491L93.5189 33.398H98.4044H99.5675C103.639 33.2817 106.779 36.4223 106.779 40.3772Z'
				fill='#474E61'
			/>
			<path
				opacity='0.1'
				d='M93.0539 59.8027C90.6112 59.8027 88.5175 60.7333 87.0053 62.2454C85.4932 63.7576 84.4463 65.9677 84.4463 68.2941C84.4463 68.4104 84.4463 68.643 84.4463 68.7593C84.6789 73.2958 88.5175 76.9017 93.0539 76.9017H106.78V59.8027H93.0539Z'
				fill='url(#paint0_linear_2136_103670)'
			/>
			<g filter='url(#filter0_d_2136_103670)'>
				<path
					d='M106.779 56.5449V58.6387C106.663 58.6387 106.547 58.6387 106.314 58.6387H92.3557C89.913 58.6387 87.8192 59.5692 86.3071 61.0814C84.9112 62.4772 83.8644 64.571 83.748 66.7811C83.748 66.8974 83.748 67.13 83.748 67.2463C83.748 72.0154 87.5866 75.854 92.3557 75.854H106.314C107.012 75.854 107.594 75.6213 107.826 75.2724C107.943 75.1561 108.059 74.9234 108.059 74.8071V57.5918C108.059 57.0102 107.477 56.6612 106.779 56.5449Z'
					fill='#5E6882'
				/>
			</g>
			<path
				d='M91.6581 72.365C94.4498 72.365 96.7762 70.0386 96.7762 67.247C96.7762 64.4553 94.4498 62.1289 91.6581 62.1289C88.8664 62.1289 86.54 64.4553 86.54 67.247C86.54 70.0386 88.8664 72.365 91.6581 72.365Z'
				fill='#474E61'
			/>
			<path
				d='M47.013 71.8874C48.935 71.8874 50.5612 70.2611 50.5612 68.3392C50.5612 66.4173 48.935 64.791 47.013 64.791C45.0911 64.791 43.4648 66.4173 43.4648 68.3392C43.4648 70.2611 45.0911 71.8874 47.013 71.8874Z'
				fill='#989FB0'
			/>
			<path
				d='M74.2161 71.8874C76.1381 71.8874 77.7643 70.2611 77.7643 68.3392C77.7643 66.4173 76.1381 64.791 74.2161 64.791C72.2942 64.791 70.668 66.4173 70.668 68.3392C70.668 70.409 72.2942 71.8874 74.2161 71.8874Z'
				fill='#989FB0'
			/>
			<path d='M46.8745 56.6001L39.0693 60.8145L40.1229 62.7657L47.9281 58.5514L46.8745 56.6001Z' fill='#989FB0' />
			<path d='M73.7331 56.5446L72.6797 58.4961L80.4857 62.7095L81.5391 60.758L73.7331 56.5446Z' fill='#989FB0' />
			<path
				d='M60.6142 80.1663C62.9004 80.1663 64.7537 78.7763 64.7537 77.0617C64.7537 75.347 62.9004 73.957 60.6142 73.957C58.328 73.957 56.4746 75.347 56.4746 77.0617C56.4746 78.7763 58.328 80.1663 60.6142 80.1663Z'
				fill='#989FB0'
			/>
			<defs>
				<filter
					id='filter0_d_2136_103670'
					x='61.748'
					y='45.5449'
					width='68.3105'
					height='63.3086'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='11' />
					<feGaussianBlur stdDeviation='11' />
					<feColorMatrix type='matrix' values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2136_103670' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2136_103670' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_2136_103670'
					x1='95.6147'
					y1='60.3967'
					x2='95.6147'
					y2='69.9942'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#B0BACC' />
					<stop offset='1' stopColor='#969EAE' />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default NoWalletIcon
