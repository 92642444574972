import React, { useContext, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { ToastContainer } from 'react-toastify'
import Banner from 'src/components/Banner'
import WalletPortfolioDrawer from 'src/components/WalletPortfolioDrawer'
import { ConfigLoadingStatus } from 'src/enums'
import { useAppDispatch, useAppSelector } from 'src/hooks/redux'
import Footer from 'src/layouts/Footer'
import Header from 'src/layouts/Header'
import { AuthProviderContext } from 'src/providers/AuthProvider'
import { openWalletDrawer } from 'src/redux/slices/application.slice'

interface Props {
	children: React.ReactNode
}

// const NotifyContent = (
// 	<p>
// 		Hourglass has transitioned to Mach Protocol! Please visit us at{' '}
// 		<a href='https://app.mach.exchange' className='text-yellow-400 underline font-bold'>Mach.Exchange</a> for 10X faster swaps.
// 	</p>
// )

// NotifyContent should be null when there is no banner to display
const NotifyContent = null

const Layout: React.FC<Props> = (props: Props) => {
	const dispatch = useAppDispatch()
	const { disconnectHandler } = useContext(AuthProviderContext)

	const [, setNavigation] = useState<string>(window.location.pathname)
	const { loading: configLoading } = useAppSelector((state) => state.configSlice)
	const { walletDrawerOpened } = useAppSelector((state) => state.applicationSlice)
	useEffect(() => {
		setNavigation(window.location.pathname)
	}, [])

	return (
		<>
			{NotifyContent && <Banner>{NotifyContent}</Banner>}
			<Header />
			<main className='flex flex-grow flex-col items-center'>
				{configLoading === ConfigLoadingStatus.loading ? (
					<img src='/gifs/sandwatch-loading.gif' alt='status' className='my-auto h-40' />
				) : configLoading === ConfigLoadingStatus.error ? (
					<div className='exchange-error-message my-auto text-center text-xl font-medium leading-8 text-red-700'>
						Our trading system is currently down for maintenance. Please try again soon.
					</div>
				) : (
					props.children
				)}
			</main>
			<Footer />
			<WalletPortfolioDrawer
				open={walletDrawerOpened}
				onClose={() => {
					dispatch(openWalletDrawer(false))
				}}
				onLogout={disconnectHandler}
			/>
			<ToastContainer />
			<Toaster
				position='bottom-center'
				reverseOrder={false}
				gutter={8}
				containerClassName=''
				containerStyle={{}}
				toastOptions={{
					className: '',
					duration: 5000,
					style: {
						background: '#363636',
						color: '#fff',
						wordBreak: 'break-word',
					},
					success: {
						duration: 3000,
					},
				}}
			/>
		</>
	)
}

export default Layout
