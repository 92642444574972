export const formatNumber = (value: string | number | undefined): string => {
	if (value == undefined || value === '') {
		return ''
	}
	if (value === 0) {
		return '0' // If value is exactly 0, return '0'
	} else {
		const numValue = parseFloat(value.toString())

		if (isNaN(numValue)) {
			return '' // Return empty string if the value is not a number
		}

		// Floor the number after 6 decimal places
		const roundedValue = (Math.floor(numValue * 1000000) / 1000000).toFixed(6)

		// Remove trailing zeros after the decimal point
		const formattedValue = parseFloat(roundedValue).toString()

		return formattedValue
	}
}
