import { useMemo } from 'react'
import officialCCTPABI from 'src/contract/abi/cctpOfficialContract.abi.json'
import orderBookABI from 'src/contract/abi/orderBookContract.abi.json'
import { ExchangeType } from 'src/enums'
import { useAppSelector } from './redux'
import { useExchangeType } from './useExchangeType'

export const useSwapContract = (sellData: IExchangeData, buyData: IExchangeData) => {
	const { swapContracts } = useAppSelector((state) => state.configSlice)
	const exchangeType = useExchangeType(sellData, buyData)
	const contractABI = useMemo(() => {
		if (exchangeType === ExchangeType.multi) {
			return orderBookABI
		} else if (exchangeType === ExchangeType.cctp) return officialCCTPABI
		else throw new Error('Invalid exchange type')
	}, [exchangeType])

	const address = useMemo(() => {
		const contractsObj = swapContracts[exchangeType]

		return contractsObj?.[sellData.networkId] as `0x${string}`
	}, [sellData, exchangeType])

	return { abi: contractABI, address }
}
