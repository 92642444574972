import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialStateProps = {
	sellData: IExchangeData
	buyData: IExchangeData
	exchangeId: string
	currentModalId: string
	walletDrawerOpened: boolean
	faucetAddresses: Record<string, boolean>
}

const initialState: InitialStateProps = {
	sellData: { value: '', networkId: 43114 },
	buyData: { value: '', networkId: 42161 },
	exchangeId: '',
	currentModalId: '',
	walletDrawerOpened: false,
	faucetAddresses: JSON.parse(localStorage.getItem('faucet') || '{}'),
}

export const applicationSlice = createSlice({
	name: 'application',
	initialState,
	reducers: {
		setSellData(state, action: PayloadAction<any>) {
			state.sellData = { ...state.sellData, ...action.payload }
		},
		setBuyData(state, action: PayloadAction<any>) {
			state.buyData = { ...state.buyData, ...action.payload }
		},
		setExchangeId(state, action: PayloadAction<string>) {
			state.exchangeId = action.payload
		},
		setCurrentModalId(state, action: PayloadAction<string>) {
			state.currentModalId = action.payload
		},
		openWalletDrawer(state, action: PayloadAction<boolean>) {
			state.walletDrawerOpened = action.payload
		},
		setFaucetAddresses(state, action: PayloadAction<any>) {
			const { address, isFaucet } = action.payload
			state.faucetAddresses[address] = isFaucet
			localStorage.setItem('faucet', JSON.stringify(state.faucetAddresses))
			return state
		},
	},
})

export const { setSellData, setBuyData, setExchangeId, setCurrentModalId, openWalletDrawer, setFaucetAddresses } =
	applicationSlice.actions

export default applicationSlice.reducer
