// Found under "assets" of "Production Mainnet" deployment in config_mach_mm.json of golang stack
export const SUPPORTED_TOKENS_ON_GO_STACK = [
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    "0x55d398326f99059fF775485246999027B3197955",
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    "0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409",
    "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "0xcebA9300f2b948710d2653dD7B07f33A8B32118C",
    "0x48065fbBE25f71C9282ddf5e1cD6D6A887483D5e"
]