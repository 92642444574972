import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { apiGetCheckPoints, apiGetLeaderboard } from 'src/api/point.api'
import { useAccount } from 'wagmi'
import { CheckPoints } from './CheckPoints'
import { Leaderboard } from './Leaderboard'

type Props = {}

const tabs = ['Check Miles', 'Leaderboard']

type ILeaderboard = {
	wallet: string
	points: number
	rank: number
}

const PointsPage: React.FC<Props> = () => {
	const { address } = useAccount()
	const [loading, setLoading] = useState<boolean>(false)
	const [leaderboardData, setLeaderboardData] = useState<ILeaderboard[]>([])

	const [selectedTab, setSelectedTab] = useState<string>(tabs[0])

	// Initialize points from localStorage if available
	const [points, setPoints] = useState(() => {
		const savedPoints = localStorage.getItem('points')
		return savedPoints ? parseInt(savedPoints) : 0
	})

	const fetchPoints = async () => {
		if (!address) return

		const { points }: { points: number } = await apiGetCheckPoints(address)

		if (points) {
			setPoints(points)
			localStorage.setItem('points', points.toString()) // Save new points to localStorage
		} else {
			setPoints(0 as number)
			localStorage.setItem('points', points.toString()) // Save new points to localStorage
		}
	}

	const fetchLeaderboard = async () => {
		setLoading(true)
		try {
			const data: ILeaderboard[] = await apiGetLeaderboard()
			const sortedData = data.sort((a, b) => b.points - a.points)
			const rankedData = sortedData.map((item, index) => ({
				...item,
				rank: index + 1,
			}))
			setLeaderboardData(rankedData)
		} catch (error) {
			console.error('Error fetching leaderboard:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchPoints()
	}, [address])

	useEffect(() => {
		if (selectedTab === 'Leaderboard' && !leaderboardData.length) {
			fetchLeaderboard()
		}
	}, [selectedTab])

	return (
		<>
			<div className='mx-auto mb-5 flex space-x-5'>
				{tabs.map((tab) => (
					<div
						key={`tab-${tab}`}
						className={cn('cursor-pointer transition-all', tab === selectedTab ? 'text-[#85C3F8]' : 'text-[#B6B2BA]')}
						onClick={() => setSelectedTab(tab)}
					>
						<p className='p-2'>{tab}</p>
						<div
							className={cn('h-[2px] w-full transition-all', tab === selectedTab ? 'bg-[#85C3F8]' : 'bg-transparent')}
						/>
					</div>
				))}
			</div>

			{selectedTab === 'Check Miles' && <CheckPoints account={address} points={points} />}
			{selectedTab === 'Leaderboard' && (
				<Leaderboard account={address} loading={loading} leaderboard={leaderboardData} />
			)}
		</>
	)
}

export default PointsPage
