import { useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'
import SearchTokenInput from '../SearchTokenInput'
import CloseIcon from '../icons/Close'
import LoadingSpinner from '../icons/LoadingSpinner'

interface Props {
	title?: string
	isOpen: boolean
	onClose: () => void
	networks: Record<string, any>
	tokens: IToken[]
	selectedNetwork: string | number
	setSelectedNetwork: (__chainId: string | number) => void
	selectedToken?: IToken | undefined
	setSelectedToken: React.Dispatch<IToken | undefined>
	disabledChains?: number[]
	disabledTokens?: IToken[]
}

const TokenSelectModal: React.FC<Props> = ({
	title,
	isOpen,
	onClose,
	networks,
	tokens,
	selectedNetwork,
	setSelectedNetwork,
	selectedToken,
	setSelectedToken,
	disabledChains = [],
	disabledTokens = [],
}: Props) => {
	const [pendingSelectedNetworkId, setPendingSelectedNetworkId] = useState<number>()
	const [networkSelectorOpened, setNetworkSelectorOpened] = useState<boolean>(false)
	const [tokenSearchTerm, setTokenSearchTerm] = useState('')

	const onNetworkClicked = (__networkId: number | string) => {
		try {
			setPendingSelectedNetworkId(+__networkId)
			setSelectedNetwork(__networkId)
		} catch (err) {
			setPendingSelectedNetworkId(undefined)
		}
	}

	useEffect(() => {
		setPendingSelectedNetworkId(undefined)
	}, [selectedNetwork])

	const searchedTokens = useMemo(() => {
		return tokens.filter((token) => token.name.toLowerCase().includes(tokenSearchTerm.toLowerCase()))
	}, [tokenSearchTerm, tokens])

	useEffect(() => {
		if (!isOpen) {
			setNetworkSelectorOpened(false)
			setTokenSearchTerm('')
		}
	}, [isOpen])

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			ariaHideApp={false}
			className='fixed left-1/2 top-1/2 w-[420px] max-w-[calc(100%-24px)] -translate-x-1/2 -translate-y-1/2 rounded-2xl border border-modal-border/20 bg-modal-background p-5 outline-none'
			style={{
				overlay: {
					backgroundColor: 'rgba(0,0,0,.6)',
				},
			}}
		>
			{/* <div className='z-[10] w-[420px] rounded-2xl border border-modal-border bg-[#2C2C2C] p-5'> */}
			<div className='flex items-center justify-between'>
				<span className='text-sm font-semibold'>Select a token</span>
				<CloseIcon className='cursor-pointer' onClick={onClose} />
			</div>

			<div className='mt-4 flex items-center gap-2'>
				<SearchTokenInput className='flex-grow' onValueChange={(val) => setTokenSearchTerm(val)} />
				<div className='relative inline-block'>
					<div
						className='cursor-pointer rounded-lg bg-component-background p-2'
						onClick={() => setNetworkSelectorOpened(true)}
					>
						<img src={networks[selectedNetwork].icon} alt='network' className='z-1 relative h-5 w-5 rounded-lg' />
					</div>
					<div
						className={cn(
							'absolute right-0 top-12 z-10 flex max-h-[400px] flex-col overflow-auto rounded-lg border border-white/10 bg-modal-background p-2',
							networkSelectorOpened ? 'opacity-100' : 'pointer-events-none select-none opacity-0'
						)}
					>
						{Object.keys(networks).map((networkId) => (
							<button
								key={`network-chip-${networkId}`}
								className={cn(
									'flex cursor-pointer select-none items-center space-x-[6px] rounded-lg px-3 py-2 transition-all hover:bg-highlight-text/10 disabled:cursor-not-allowed disabled:bg-[rgba(100,100,100,.4)] disabled:text-white/60 disabled:contrast-50 disabled:grayscale',
									+networkId === +selectedNetwork ? ' bg-highlight-text/20' : ''
								)}
								onClick={() => {
									onNetworkClicked(networkId)
									setNetworkSelectorOpened(false)
								}}
								disabled={disabledChains && disabledChains.findIndex((dId) => +dId === +networkId) >= 0}
							>
								<div className='relative h-6 w-6'>
									{title?.includes('Send') && pendingSelectedNetworkId && +pendingSelectedNetworkId === +networkId && (
										<LoadingSpinner className='absolute -left-[2px] -top-[2px] z-0 h-7 w-7 animate-spin' />
									)}
									<img src={networks[networkId].icon} alt='network' className='z-1 relative h-6 w-6 rounded-full	' />
								</div>
								<span className='text-sm font-medium'>{networks[networkId].name}</span>
							</button>
						))}
					</div>
					{networkSelectorOpened && (
						<div className='fixed inset-0' onClick={() => setNetworkSelectorOpened(false)}></div>
					)}
				</div>
			</div>

			<p className='mt-6 text-sm'>Token</p>
			<div className='-m-5 mt-3 flex h-[300px] max-h-[calc(100vh-200px)] flex-col overflow-auto'>
				{searchedTokens.map((token) => (
					<button
						key={`token-chip-${token.address}`}
						className={cn(
							'flex cursor-pointer select-none items-center space-x-3 px-5 py-2 transition-all hover:bg-component-background hover:opacity-80 disabled:cursor-not-allowed disabled:bg-[rgba(100,100,100,.4)] disabled:text-white/60 disabled:contrast-50 disabled:grayscale',
							token.address === selectedToken?.address ? 'bg-component-background' : ''
						)}
						onClick={() => setSelectedToken(token)}
						disabled={
							disabledTokens &&
							disabledTokens.findIndex(
								(dToken) => +dToken.chainId === +selectedNetwork && +dToken.address === +token.address
							) >= 0
						}
					>
						<img src={token.logoURI} alt='network' className='h-9 w-9 rounded-full' />
						<div className='flex flex-col items-start'>
							<p>{token.name}</p>
							<p className='text-xs font-medium text-gray-400'>{token.symbol}</p>
						</div>
					</button>
				))}
			</div>
			{/* </div> */}
		</Modal>
	)
}

export default TokenSelectModal
