import React, { useContext } from 'react'
import backgroundImage from 'src/assets/images/points-bg.png'
import Card from 'src/components/Card'
import ConnectButton from 'src/components/buttons/ConnectButton'
import WalletIcon from 'src/components/icons/Wallet'
import { AuthProviderContext } from 'src/providers/AuthProvider'

export const ConnectWallet: React.FC = () => {
	const authContext = useContext(AuthProviderContext)
	const name = window.location.href.includes('hourglass') ? 'Hourglass' : 'Mach'

	return (
		<Card>
			<div className='flex flex-col gap-2.5'>
				<div className='relative flex h-60 flex-col items-center justify-center rounded-lg p-6'>
					<img src={backgroundImage} className='absolute left-0 top-0 z-[0] h-full w-full' />
					<h2 className='relative z-[1] text-center text-4xl font-medium italic text-white'>
						Welcome to {name} Miles
					</h2>
				</div>
				<div className='flex flex-col items-center justify-center rounded-lg bg-black/25 p-4'>
					<span className='pt-2 text-[10px]'>Please connect your wallet to register or check your miles.</span>
				</div>
				<ConnectButton />
			</div>
		</Card>
	)
}
