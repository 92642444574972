import { useMemo } from 'react'
import { THRESHOLD_TO_SEND_TO_CCTP } from 'src/configs/numbers'
import { ExchangeType } from 'src/enums'

export const useExchangeType = (sellData: IExchangeData, buyData: IExchangeData) => {
	const exchangeType: ExchangeType = useMemo(() => {
		//only return cctp if selldata value is over 1000
		if (!sellData.value) return ExchangeType.multi
		let sellDataNumber

		if (typeof sellData.value === 'string') sellDataNumber = parseFloat(sellData.value)
		else sellDataNumber = sellData.value

		// if both tokens are USDC and on different networks, return cctp
		const isUSDCTransaction = sellData.token?.symbol == 'USDC' && buyData.token?.symbol == 'USDC'
		const sendingToDifferentNetwork = sellData.networkId !== +buyData.networkId

		// if the source or destination chain is Celo then it CAN NOT use cctp
		const isCeloTransaction = sellData.token?.chainId === 42220 || buyData.token?.chainId === 42220

		// it must be higher than a certain threshold to use cctp
		const higherThanThreshold = sellDataNumber > THRESHOLD_TO_SEND_TO_CCTP

		// if the sellData or buyData network is mainnet, return cctp even if it doesnt pass the threshold
		const isEthTransaction = sellData.networkId == 1 || buyData.networkId === 1

		if (
			isUSDCTransaction &&
			sendingToDifferentNetwork &&
			!isCeloTransaction &&
			(higherThanThreshold || isEthTransaction)
		) {
			return ExchangeType.cctp
		} else return ExchangeType.multi
	}, [sellData, buyData])

	return exchangeType
}
