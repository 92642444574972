import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from 'react-redux'
import type { AppDispatch, RootState } from 'src/redux/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
