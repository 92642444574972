import { useWeb3Modal } from '@web3modal/wagmi/react'
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAccount, useDisconnect, useSignMessage } from 'wagmi'
import { useSupportedChain } from '../hooks/useSupportedChain'
import { setConnectedSign } from '../redux/slices/landing.slice'

interface AuthProvider {
	signStatus: boolean
	disconnectHandler: () => void
	connectHandler: () => Promise<void>
}

export const AuthProviderContext = createContext<AuthProvider>({
	signStatus: false,
	disconnectHandler: () => {},
	connectHandler: async () => {},
})

interface Props {
	children: React.ReactElement
}

export const AuthProvider = (props: Props) => {
	const dispatch = useDispatch()

	const { signMessageAsync } = useSignMessage()
	const { address, isConnected } = useAccount()
	const { open } = useWeb3Modal()
	const { disconnectAsync } = useDisconnect()
	const { isValidChain } = useSupportedChain()

	const [signStatus, setSignStatus] = useState<boolean>(!isConnected || !window.localStorage.getItem('sign'))
	const [connectedManually, setConnectedManually] = useState<boolean>(false)

	//if url contains "hourglass", set name to Hourglass, else set name to Mach
	const name = window.location.href.includes('hourglass') ? 'Hourglass' : 'Mach'

	const connectHandler = async () => {
		setConnectedManually(true)
		await open()
	}

	const disconnectHandler = useCallback(async () => {
		try {
			localStorage.clear()
			dispatch(setConnectedSign({ sign: '' }))
			setSignStatus(false)
			await disconnectAsync()
		} catch (err) {
			console.log(err)
		}
	}, [dispatch])

	const getSignature = useCallback(async () => {
		try {
			if (!isValidChain || !address) return
			const message = `Welcome to the ${name} Protocol platform on ${
				process.env.REACT_APP_DAPP_ENV == 'mainnet' ? 'mainnet' : 'testnet'
			}. Please sign your address to authenticate.`
			const signature = await signMessageAsync({ message })
			localStorage.setItem('sign', signature)
			dispatch(setConnectedSign({ sign: signature }))
			setSignStatus(!isConnected || !signature)
		} catch (err) {
			disconnectHandler()
		}
	}, [address, isConnected, disconnectHandler, dispatch, isValidChain])

	useEffect(() => {
		;(() => {
			const sign = localStorage.getItem('sign')
			if (isConnected) {
				if (!sign && connectedManually) {
					getSignature()
				} else if (!connectedManually && !sign) {
					disconnectHandler()
				} else if (sign) {
					dispatch(setConnectedSign({ sign: sign }))
				}
			}
			setSignStatus(!isConnected || !window.localStorage.getItem('sign'))
		})()
	}, [isConnected, connectedManually])

	const value = useMemo(() => {
		return {
			signStatus,
			disconnectHandler,
			connectHandler,
		}
	}, [signStatus])

	return <AuthProviderContext.Provider value={value}>{props.children}</AuthProviderContext.Provider>
}
